import React, {useState} from 'react';
import doubleQuotes from '../assets/Icons/doubleQ.png';
import doubleQuotes1 from '../assets/Icons/doubleQ1.png';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import bgTestimonial from '../assets/img/Home/Group 8445.png';
import bgTestimonial1 from '../assets/img/Home/Group 4.png';
import client from '../assets/img/Testimonials/Picture1.jpg';
import client1 from '../assets/img/Testimonials/Ellipse 15.png';
import client2 from '../assets/img/Testimonials/Ellipse 26.png';
import client3 from '../assets/img/Testimonials/Ellipse 30.png';

import '../css/Main.css';



const TestimonialsPage = () => {
  
    const [currentIndex, setCurrentIndex] = useState(0);

    const testimonials = [
      {
        name: 'Mohammed Imran Ali',
        work: 'Support worker in social care,',
        text: 'I saw a recruiting banner and so RHC360 solutions was one of the first places I went to after landing in the UK. As a newcomer to the country, I had little idea about how to get started but WAYNE and his team guided me through. They provided bespoke advice and got me on the rotas for shifts that were perfectly suited to my needs. They have been very supportive and transparent in their work which is a great quality. I also never faced any problems regarding payments as all payments were done correctly and timely as discussed at the beginning. Overall, it worked out well and in a very professional manner.',
        image: client,
      },
      {
        name: 'Bhanu Prasad',
        work : 'Senior HCA,',
        text: 'I first encountered RHC360 solutions through a recruiting banner, and it was one of my initial destinations upon arriving in the UK. As a newcomer, I was unsure of how to begin my career here, but WAYNE and his team provided invaluable guidance. They offered bespoke advice and seamlessly integrated me into the rotas with shifts that perfectly matched my needs. Their support and transparency have been exemplary qualities. Additionally, I have never encountered any issues with payments; everything was handled correctly and on time, just as discussed from the start. Overall, my experience has been very positive, conducted in a highly professional manner.',
        image: client1,
      },
      {
        name: 'Choice Okabova',
        work : 'Senior HCA,',
        text: 'I first learned about RHC360 solutions through a phone call, and it quickly became my go-to destination upon arriving in the UK. As a newcomer, I was unsure of how to begin my career here, but WAYNE and his team provided invaluable guidance. They offered bespoke advice and seamlessly integrated me into the rotas with shifts that perfectly matched my needs. Their support and transparency have been exemplary qualities. Additionally, I have never encountered any issues with payments; everything was handled correctly and on time, just as discussed from the start. Overall, my experience has been very positive, conducted in a highly professional manner.',
        image: client2,
      },
    ];
    

    const prevTestimonial = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };
  
    const nextTestimonial = () => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };
  
    const { name, work, text, image } = testimonials[currentIndex];

    
  return (
    <section>
      <div className="mx-[50px] my-[10px] bg-[#F4FEFF] py-12">
      <div className="">
          <h4 data-aos="fade-up" className="font-bold mb-2 home-text2">Testimonials</h4> 
          <h1 data-aos="zoom-in" className="font-bold text-[20px] md:text-[36px] mb-2">What <span className="home-text">Candidates Say</span> About Us</h1>
        </div>
          <div data-aos="zoom-in" className="flex flex-col text-center">
            <div className="relative">
            <div className="my-4">
              <p className="text-[#323b4b] text-[20px] md:text-[30px] font-medium my-10 ml-[10%] w-[80%]"><img src={doubleQuotes} className="absolute top-0 left-0 w-6 h-6 flex"/>{text}<img src={doubleQuotes1} className="mt-5 absolute bottom-50 right-0 w-6 h-6 flex"/></p>
            </div>
              <img
                className="mt-5 w-24 h-24 rounded-full mx-auto border-4 border-[#0082DA]"
                src={image}
                alt={name}
              />
              
            </div>

            
            <div className="mt-4">
              <p className="text-3 font-bold">{name}</p>
              <p className="mt-1 text-3 font-medium">{work}</p>
            </div>
          </div>
          <div className="mt-5 flex items-center justify-center">
            <button onClick={prevTestimonial} className="focus:outline-none">
            <FaArrowLeft className="text-[#0082DA] text-2xl mx-4" />
          </button>
          <div className="flex space-x-2">
            {testimonials.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => setCurrentIndex(index)}
              ></span>
            ))}
          </div>
          <button onClick={nextTestimonial} className="focus:outline-none">
            <FaArrowRight className="text-[#0082DA] text-2xl mx-4" />
          </button>
        </div>
    </div> 
    </section>
  );
}

export default TestimonialsPage;

import React, { useState } from 'react';
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible, AiFillInfoCircle } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../assets/Logo/logo.png";
import loginImg from "../assets/img/Login/Rectangle 4620.png";
import overlayImg from "../assets/img/Login/Rectangle 4621.png";
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleChange = (e) => { 
    const { name, value } = e.target;
  
    if (name === 'phone') {
      // Validate phone number: optional + followed by digits only, with a max of 13 characters
      if (/^\+?\d*$/.test(value) && value.length <= 13) {
        setFormData({
          ...formData,
          [name]: value
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const validatePassword = (password) => {
    const criteria = {
      minLength: /.{8,}/,
      upperCase: /[A-Z]/,
      lowerCase: /[a-z]/,
      number: /[0-9]/,
      specialChar: /[!@#?]/,
    };

    const failedCriteria = {};

    if (!criteria.minLength.test(password)) failedCriteria.minLength = true;
    if (!criteria.upperCase.test(password)) failedCriteria.upperCase = true;
    if (!criteria.lowerCase.test(password)) failedCriteria.lowerCase = true;
    if (!criteria.number.test(password)) failedCriteria.number = true;
    if (!criteria.specialChar.test(password)) failedCriteria.specialChar = true;

    return Object.keys(failedCriteria).length === 0 ? null : failedCriteria;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordErrors = validatePassword(formData.password);
    if (formData.password !== formData.confirmPassword) {
      setErrors({ password: 'Password doesn’t match. Please re-enter correct password' });
      return;
    }

    if (passwordErrors) {
      setErrors({ ...passwordErrors });
      setShowPopup(true);
      return;
    }

    try {
      const response = await axios.post('https://ramsy-health-backend.vercel.app/api/users/register', formData);
      toast.success(response.data.msg || 'Registration successful');
      setFormData({
        name: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
      });
      setErrors({});
      setShowPopup(false); // Hide password instructions on successful registration
      navigate('/SignIn');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        toast.error(error.response.data.msg);
      } else {
        toast.error('Error in registration');
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center">
      <ToastContainer />
      <form className="bg-white px-12 py-4 rounded shadow-md w-full max-w-lg" onSubmit={handleSubmit}>
        <a href="/"> <img src={logo} alt="RHC 360 Solutions" className="justify-start h-28 w-40" /></a>
        <h2 className="my-4 text-xl font-bold">Welcome, Sign up to RHC 360 Solutions</h2>

        <div className='mt-10 flex flex-row gap-4'>
          <div className="mb-4 w-full">
            <input
              type="text"
              name="name"
              placeholder="First Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-full">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          </div>

          <div className='flex flex-row gap-4'>
          <div className="mb-4 w-full">
            <input
              type="email"
              name="email"
              placeholder="Email ID"
              value={formData.email}
              onChange={handleChange}
              required
              className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4 w-full">
          <input 
            type="text"
            name="phone"
            placeholder="PhoneNumber(Optional)"
            value={formData.phone}
            minLength={13}
            maxLength={13}
            onChange={handleChange}
            className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          </div>
          </div>
          

        <div className="relative mb-4">
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
            <button onClick={togglePasswordVisibility} type="button" className="focus:outline-none">
              {showPassword ? <AiFillEyeInvisible size={16} /> : <AiFillEye size={16} />}
            </button>
            <button onClick={togglePopup} type="button" className="focus:outline-none">
              <AiFillInfoCircle size={16} />
            </button>
          </div>
        </div>

        {showPopup && (
          <div className="relative mb-4">
            <div className="top-full right-0 w-100 p-4 border border-gray-300 rounded shadow-lg bg-blue-50">
              <h3 className="font-bold text-red-500">Password Instructions</h3>
              <ul className="mt-2 list-disc list-inside text-sm">
                <li className={errors.minLength ? 'font-bold text-red-500' : ''}>At least 8 characters—the longer, the better.</li>
                <li className={`${errors.upperCase ? 'font-bold text-red-500' : ''} ${errors.lowerCase ? 'font-bold text-red-500' : ''}`}>A mixture of both uppercase and lowercase letters.</li>
                <li className={errors.number ? 'font-bold text-red-500' : ''}>A mixture of letters and numbers.</li>
                <li className={errors.specialChar ? 'font-bold text-red-500' : ''}>Inclusion of at least one special character, e.g., ! @ # ?</li>
              </ul>
            </div>
          </div>
        )}

        <div className="relative mb-4">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className={`hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.password && 'border-red-500'}`}
          />
          <button onClick={toggleConfirmPasswordVisibility} type="button" className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none">
            {showConfirmPassword ? <AiFillEyeInvisible size={16} /> : <AiFillEye size={16} />}
          </button>
          {errors.password && <p className="text-red-500 text-xs italic mt-2">{errors.password}</p>}
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Sign up
          </button>
        </div>

        <p className="mt-4">
          Already have an account? <a href="/SignIn" className="font-bold text-blue-500">Sign in</a>
        </p>

        {/* <p className="text-center text-[12px] mt-4">
          OR
        </p>

        <div className="mt-4">
          <button className="bg-white border border-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full flex items-center justify-center">
            <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google" className="mr-2" />
            Continue with Google
          </button>
        </div> */}
      </form>

      <div className="relative w-full max-w-md hidden lg:flex flex items-center justify-center">
        <div className="relative w-full h-full flex items-center justify-center">
          <img src={loginImg} alt="Login" className='object-cover rounded shadow-md w-full h-full' />
          <div className="absolute inset-0 bg-transparent">
            <img src={overlayImg} alt="Overlay" className="object-cover w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;

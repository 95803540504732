import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AnimationProvider = ({ children }) => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({ duration: 1000 });

    // Function to reinitialize AOS
    const handleScroll = () => {
      AOS.refresh();
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <>{children}</>;
};

export default AnimationProvider;

import React from 'react';
import SL from '../../assets/img/Home/Rectangle 4611.png';
import overlayImg from "../../assets/img/Login/Rectangle 4621.png";
import JobUpdates from '../../component/JobUpdates';
import Footer from '../../component/Footer';
import HeaderHome from '../../component/HeaderHome';

function SupportedLiving() {

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-auto lg:mx-0 py-8 px-4 sm:px-6 lg:px-8">
  <div className="text-center md:text-start w-full lg:w-[70%] mx-auto lg:mx-0">
    <h1 className="text">Innovative Services and Solutions for a Healthier Tomorrow.</h1>
    {/* <p className="text-2">"Discover how cutting-edge technologies and patient-centered care are revolutionising healthcare services, improving outcomes, and ensuring a healthier future for all."</p> */}
  </div>
</div>

      </div>
      <div className="mt-16 mx-auto px-4 lg:px-[50px] justify-center w-full h-full">
        <div className="w-full text-center">
          <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[24px] sm:text-[50px] md:text-[70px] lg:text-[90px] font-normal z-20'>Supported Living</div>
            <img src={SL} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Supported Living</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">At RHC 360 Solutions, we believe that every individual deserves access to high-quality healthcare services that prioritise their well-being, independence, and overall quality of life. As a trusted provider of healthcare services, we are committed to offering a range of specialised care services that cater to the diverse needs of our clients.</p>
          <p data-aos="zoom-in" className="text-3 mt-12">Our team of skilled and compassionate healthcare professionals are dedicated to delivering exceptional care that is tailored to the unique needs of each individual. We take pride in offering bespoke care packages that are designed to promote independence and enhance the overall quality of life of our clients. What sets us apart from other healthcare providers is our unwavering commitment to putting our clients first, every time.</p>
          <div data-aos="zoom-in" className='mt-12'>
            <a href="/ContactUs" target="blank" className="bg-[#0082DA] text-2 font-bold py-2 px-5 lg:py-3 lg:px-6 rounded">Book an appointment</a>
          </div>
        </div>
      </div>
      <div className="mx-[40px] my-[100px]">
        <JobUpdates />
      </div>
      <Footer />
    </>
  );
}

export default SupportedLiving;

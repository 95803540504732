import React, { useState, useEffect } from 'react';
import JobUpdates from '../component/JobUpdates';
import DropdownSection from '../component/DropdownSection';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../component/Footer';
import axios from 'axios';
import HeaderHome from '../component/HeaderHome';
import { Range, getTrackBackground } from 'react-range';

function JobsPageInternational() {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [locationTerm, setLocationTerm] = useState('');
  const [sector, setSector] = useState('');
  const [selectedJobTypes, setSelectedJobTypes] = useState({
    Permanent: false,
    PartTime: false,
    Contract: false,
    Internship: false,
  });
  const [selectedEmploymentTypes, setEmploymentTypes] = useState({
    'Full_time': false,
    'Part_time': false,
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
        fetchJobs(code);
}, []);

const fetchJobs = (code) => {
    fetch('https://ramsy-health-backend.vercel.app/api/CrmJobs/CrmJobs', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(response => response.json())
    .then(data => {
      console.log("data",data);
        if (data.result.items) {
            setJobs(data.result.items);
            console.log('Jobs fetched:', data.resul.items);
        } else {
            console.error('Error fetching jobs:', data.error);
        }
    })
    .catch(error => {
        console.error('Error fetching jobs:', error);
    });
};


  const handleJobClick = (id) => {
    navigate(`/job/${id}`);
  };

  const handleJobTypeChange = (e) => {
    const { name, checked } = e.target;
    setSelectedJobTypes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleEmploymentTypeChange = (e) => {
    const { name, checked } = e.target;
    setEmploymentTypes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };


  const handleSearch = () => {
  };

  const isFilterSelected = (filter) => Object.values(filter).some((value) => value);

  const filteredJobs = jobs.filter((job) => {
    const jobNameMatch =
      job.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.employment_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.job_type.toLowerCase().includes(searchTerm.toLowerCase()) 
      const jobTypeMatch =
      Object.keys(selectedJobTypes).some((type) => selectedJobTypes[type] && job.job_type.toLowerCase().includes(type.toLowerCase())) ||
      !isFilterSelected(selectedJobTypes);
      const EmploymentTypeMatch =
      Object.keys(selectedEmploymentTypes).some((EmploymentType) => selectedEmploymentTypes[EmploymentType] && job.employment_type.toLowerCase().includes(EmploymentType.toLowerCase())) ||
      !isFilterSelected(selectedEmploymentTypes);
      const sectorMatch = sector === '' || job.Sector === sector;

    return  jobNameMatch && jobTypeMatch && EmploymentTypeMatch && sectorMatch;
  });

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-[10px] mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="text-center sm:text-start">
            <h1 className="text-white text-2xl mb-4">Discover your ideal job role here.</h1>
            <div className="bg-white rounded-xl shadow-lg flex flex-col sm:flex-row items-center p-2 space-y-2 sm:space-y-0 sm:space-x-2">
              <select
                className="bg-transparent border-none outline-none text-gray-500 p-2 rounded-full w-full sm:w-1/4"
                value={sector}
                onChange={(e) => setSector(e.target.value)}
              >
                <option value="">Select sector</option>
                <option value="Clinical">Clinical</option>
                <option value="NON Clinical">NON Clinical</option>
              </select>
              <input
                type="text"
                placeholder="Job title or Keyword"
                className="bg-transparent border-none outline-none flex-grow p-2 text-gray-700 w-full sm:w-auto"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <input
                type="text"
                placeholder="Set your location"
                className="bg-transparent border-none outline-none flex-grow p-2 text-gray-700 w-full sm:w-auto"
                value={locationTerm}
                onChange={(e) => setLocationTerm(e.target.value)}
              />
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded-xl hover:bg-blue-700 w-full sm:w-auto"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="md:mx-[10px] p-4 flex flex-row lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
        <div data-aos="zoom-in" className="w-1/3 lg:w-1/4 bg-white p-4 rounded-md shadow-md">
          <DropdownSection title="Job Type">
            {['Permanent', 'PartTime', 'Contract', 'Internship'].map((type) => (
              <label key={type} className="flex items-center">
                <input
                  type="checkbox"
                  name={type}
                  checked={selectedJobTypes[type]}
                  onChange={handleJobTypeChange}
                  className="mr-2"
                />
                {type}
              </label>
            ))}
          </DropdownSection>
          <DropdownSection title="Employment Type">
            {Object.keys(selectedEmploymentTypes).map((EmploymentType) => (
              <label key={EmploymentType} className="flex items-center">
                <input
                  type="checkbox"
                  name={EmploymentType}
                  checked={selectedEmploymentTypes[EmploymentType]}
                  onChange={handleEmploymentTypeChange}
                  className="mr-2"
                />
                {EmploymentType}
              </label>
            ))}
          </DropdownSection>
          </div>
        <div className="w-full lg:w-3/4 p-4">
          <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <h2 className="text-xs sm:text-lg lg:text-xl font-bold mb-2 md:mb-0">Showing: {filteredJobs.length} filtered jobs</h2>
            <label className='text-xs sm:text-[16px]'>
              Sort by :
              <select className="p-2 rounded-md">
                <option>Past 24 hours</option>
                <option>Past 1 week</option>
                <option>Past 1 month</option>
              </select>
            </label>
          </div>
          <div data-aos="zoom-in" className="space-y-4">
            {filteredJobs.map((job) => (
              <div
                key={job.id}
                className="flex flex-col md:flex-row bg-white p-4 rounded-md shadow-md items-center justify-between cursor-pointer"
                onClick={() => handleJobClick(job.id)}
              >
                <div className="flex items-center w-full md:w-[75%] mb-4 md:mb-0">
                  <div>
                    <h3 className="text-lg"><span className='font-bold'>JOB TITLE : </span>{job.job_title}</h3>
                    <h6 className="text-xs"><span className='font-bold'>EMPLOYMENT TYPE : </span>{job.employment_type}</h6>
                    <h3 className="text-xs"><span className='font-bold'>JOB TYPE : </span>{job.job_type}</h3>
                  </div>
                </div>
                </div>
            ))}
          </div>
        </div>
      </div>

      <JobUpdates className="mx-auto p-4" />
      <Footer />
    </>
  );
}

export default JobsPageInternational;

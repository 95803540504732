import React from 'react';
import IR from "../../assets/img/Services/Rectangle 4622.png";
import overlayImg from "../../assets/img/Login/Rectangle 4621.png";
import JobUpdates from '../../component/JobUpdates';
import Footer from '../../component/Footer';
import HeaderHome from '../../component/HeaderHome';
import Graphic from "../../../src/assets/img/Services/website infographic.jpg";


function InternationalRecruitment() {

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-auto lg:mx-0 py-8 px-4 sm:px-6 lg:px-8">
  <div className="text-center md:text-start w-full lg:w-[70%] mx-auto lg:mx-0">
    <h1 className="text">Innovative Services and Solutions for a Healthier Tomorrow.</h1>
    {/* <p className="text-2">"Discover how cutting-edge technologies and patient-centered care are revolutionising healthcare services, improving outcomes, and ensuring a healthier future for all."</p> */}
  </div>
</div>

      </div>
      <div className="mt-16 mx-auto px-4 lg:px-[50px] justify-center w-full h-full">
        <div className="w-full text-left">
          <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[24px] sm:text-[50px] md:text-[70px] lg:text-[90px] font-normal z-20'>International Recruitment</div>
            <img src={IR} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
        <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2 text-center"><span className="home-text">RHC 360 Solutions: HTE International Recruitment Services</span></h3>
        <div className="mt-4 w-full h-auto sm:w-3/4 sm:h-auto md:w-1/2 lg:w-2/3 mx-auto">
          <img src={Graphic} alt="Graphic" className="w-full h-auto object-cover" />
        </div>
          <p data-aos="zoom-in" className="text-3 mt-1 text-center">At RHC 360 Solutions, we are committed to providing exceptional international recruitment services in compliance with the HealthTrust Europe framework. Our approach aligns with the Total Workforce Solutions III framework, specifically under the National and International Permanent Recruitment (NIPR) lots.</p>
          <p data-aos="zoom-in" className="text-7 mt-12 text-center"><span className="home-text font-extrabold">Our Compliance with HealthTrust Europe Framework</span></p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-center">Ethical Recruitment Practices</p>
          <p data-aos="zoom-in" className="text-3 mt-5">We prioritize ethical recruitment by sourcing candidates listed on the Ethical Recruitment List. Our recruitment processes are designed to align with the framework terms, ensuring that all international candidates meet the rigorous standards set by the Department of Health and Social Care.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-extrabold text-center">Comprehensive Support Packages</p>
          <p data-aos="zoom-in" className="text-3 mt-5">We offer modular support packages for independent international recruitment activities. This flexibility allows participating authorities to utilize internal resources while receiving expert guidance from our team.</p>

          <p data-aos="zoom-in" className="text-3 mt-8 font-extrabold text-center">Recruitment Process Outsourcing (RPO)</p>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC 360 Solutions provides Recruitment Process Outsourcing solutions tailored to the needs of healthcare providers. This includes the transfer of all or part of the recruitment process to our experienced team, enabling efficient and compliant recruitment of international candidates.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-center">Statement of Work (SoW) Solutions</p>
          <p data-aos="zoom-in" className="text-3 mt-5">For specific projects, we offer Statement of Work provisions, allowing us to deliver specialized expertise to support the recruitment of international professionals. This method ensures transparency and accountability throughout the recruitment process.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-center">Key Benefits</p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2"><span className="font-bold">Free Access:</span> Our services under the HealthTrust Europe framework are free to access, ensuring no hidden costs for healthcare providers.</li>
            <li className="mb-2"><span className="font-bold">Market-Leading Compliance:</span> We adhere to strict compliance requirements, ensuring that all international candidates are qualified and meet the necessary healthcare standards.</li>
            <li className="mb-2"><span className="font-bold">Customizable Solutions:</span> Our modular service provision allows healthcare providers to select the specific services they require, enhancing both internal processes and cost control.</li>
            </ul>
          <p data-aos="zoom-in" className="text-3 mt-5">By leveraging our expertise in international recruitment and our commitment to compliance, RHC 360 Solutions ensures that healthcare organizations can effectively fill vacancies with qualified professionals while adhering to the highest standards of practice.</p>

          <p data-aos="zoom-in" className="text-7 mt-20 text-center"><span className="home-text font-extrabold">Our expertise on the NIPR framework</span></p>
          <p data-aos="zoom-in" className="text-3 mt-5 text-center">RHC 360 SOLUTIONS operates as a recruitment service provider through the application of the <span className="font-bold">NIPR</span> (National Integrated Procurement Framework) to respond to the changing needs of their clients. The strategic benefits of innovative cooperation that RHC 360 SOLUTIONS conducts through NIPR Agreements are aimed at improving and protecting the management of the workforce.</p>
          <p data-aos="zoom-in" className="text-7 mt-12 text-center"><span className="home-text font-extrabold">Key Benefits of RHC 360 SOLUTIONS' for NIPR Services:</span></p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">1. Ethical Sourcing and Protection:</p>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC emphasizes ethical sourcing and a fair and responsible recruitment process for all candidates. The agreements are armed with client protections such as rebates and restrictive covenants.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-extrabold text-left">2. Assured Capability:</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A highly developed vetting process established in the organization helps evaluate capability through detailed tender assessments, assuring that only competent suppliers and candidates remain embedded in the workforce of our client.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-extrabold text-left">3. Variety Among Enhancing Supplier Access:</p>
          <p data-aos="zoom-in" className="text-3 mt-5">The client has access to a wide array of suppliers, from high-volume right through to niche and specialist providers. This variety allows organizations to truly cater to their specified recruitment needs.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">4. Performance Management Tools:</p>
          <p data-aos="zoom-in" className="text-3 mt-5">There are detailed contract terms, templates, and KPIs available to facilitate effective performance management. This allows us to monitor and assess candidate performances systematically.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">5. Traditional and Emerging Sourcing Options:</p>
          <p data-aos="zoom-in" className="text-3 mt-5">Our services provide traditional recruitment campaigns, featuring countries that traditionally offer recruiting services, in addition to providing recently emerging markets. This way, we ensure an informative and powerful talent pool.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">6. Innovative Collaborative Options:</p>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC 360 SOLUTIONS drives innovation through collaborative recruitment offerings: recruit-to-bank, recruitment process outsourcing (RPO), and workforce consultancy. These flexible solutions address the ever-changing needs of our clients.</p>
          
          
            {/* <div data-aos="zoom-in" className='flex mt-12 justify-center'>
            <a href="/ContactUs" target="blank" className="bg-[#0082DA] text-2 font-bold py-2 px-5 lg:py-3 lg:px-6 rounded">Book an appointment</a>
          </div> */}
        </div>
      </div>
      <div className="mx-[40px] my-[100px]">
        <JobUpdates />
      </div>
      <Footer />
    </>
  );
}

export default InternationalRecruitment;

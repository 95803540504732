import React from 'react';
// import AboutUsimg from '../assets/img/AboutUs/image.png';
import AboutUsimg2 from '../assets/img/AboutUs/Rectangle 17.png';
import AboutUsimg4 from '../assets/img/Home/Rectangle 26.png';
import JobUpdates from '../component/JobUpdates';
import TestimonialsPage from '../component/Testimonials';
import Footer from '../component/Footer';
import HeaderHome from '../component/HeaderHome';

function AboutUs() {
      return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-[10px] mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="text-center md:text-start w-full lg:w-[70%]">
            <h1 className="text">Your Trust Is Our Commitment.</h1>
          </div>
        </div>
      </div>
      <div className="my-[50px] mx-auto px-4 lg:px-[50px] my-5 flex flex-col-reverse lg:flex-row lg:grid lg:grid-cols-2 gap-12 items-center w-full">
      <div className="w-full">
          <img data-aos="zoom-in"  src={AboutUsimg2} alt="About Us" className="w-full md:h-[500px] lg:h-[500px] rounded-2xl" />
        </div>
        <div className="w-full">
          <h4 data-aos="fade-up" className="font-bold mb-2 home-text2">About Us</h4> 
          <h1 data-aos="zoom-in"  className="font-bold text-5 my-4">Who <span className="home-text">We</span> Are</h1>
          <p data-aos="zoom-in"  className="text-3 mt-5">We are a proud partner of multiple NHS Trusts, Care Homes, Healthcare logistics, transport services, educational institutions and hospitality service-related institutions. The ever-expanding network of partners is a result of our commitment towards the consistent provision of qualified and experienced healthcare professionals.</p>
          <p data-aos="zoom-in"  className="text-3 mt-5">Whether you require temporary nurses, locum doctors, or allied health professionals, we will provide our support services to develop a tailored strategy that delivers the right staff at the right time. Moreover, our consultative approach enables us to address your specific workforce needs from skills gaps, to shift patterns to budget constraints.</p>
        
        </div>
      </div>
      <div className="mx-[20px] lg:mx-[50px] lg:my-[50px]">
          <h1 data-aos="fade-up" className="font-bold text-[24px] md:text-[36px] mb-2 text-center">Recognition <span className="home-text">Logs</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">At our company, we take great pride in the quality of our care services. Our team of carers are trained and trusted professionals who always maintain the utmost discretion and professionalism. With our detailed care plans, our carers are able to provide personalized care that meets each client’s specific needs and preferences.</p>
          <p data-aos="zoom-in" className="text-3 mt-5">We specialize in providing enhanced care packages for individuals with complex care needs, but we also believe in taking care of the small details that make a big difference in our clients’ lives. This includes tasks like shopping, housework, and socializing.</p>
          <p data-aos="zoom-in" className="text-3 mt-5">At our company, we are committed to providing compassionate and comprehensive care to each and every one of our clients. Whether you require specialized care or just a little extra help with daily tasks, we are here to support you and your loved ones.</p>
        </div>
        <div className="lg:my-[50px] mx-auto lg:my-5 grid lg:grid-cols-2 lg:gap-5 items-center w-full">
        <div data-aos="zoom-in"  className="flex-1 w-full mt-5 lg:mt-0">
          <img src={AboutUsimg4} alt="Home Banner" className="w-full md:h-[1000px] lg:h-[700px]" />
        </div>
        <div className="flex-1 w-[93%] text-center lg:text-left">   
        <h1 data-aos="fade-up" className="font-bold text-[24px] md:text-[36px] mb-2">Recruitment <span className="home-text">Process</span></h1>     
          <p data-aos="zoom-in" className="text-3 mt-5">To  ensure our service is prompt and meticulous our consultants are divided into recruitment teams specialising in specific job categories. This division allows our consultants to have a deep understanding of the requirements and nuances of each job category within the healthcare sector. By focusing on candidate skills, competencies, experience and qualifications, our teams are able to provide tailored support and guidance to both candidates and clients, ensuring that we match the right candidate with the right role.</p>
        </div>                
        </div>
        <div className="mx-auto px-4 mt-8 lg:mt-0 lg:px-[50px] flex flex-col lg:flex-row justify-center items-center w-full h-full gap-20">
        <div className="flex-1 w-full text-center lg:text-left">   
        <h1 data-aos="fade-up" className="font-bold text-[24px] md:text-[36px] text-center">Our <span className="home-text">Compliance Process</span></h1>        
        <p data-aos="zoom-in"  className="text-3 mt-5 font-bold text-center">RHC 360 Solutions Compliance and Safeguarding Standards</p>     
        <p data-aos="zoom-in"  className="text-3 mt-5 font-bold text-center"><span className="home-text">Employment Checks for Clinical and Non-Clinical Temporary Workers</span></p>
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">1. Thorough Employment Checks:</span> RHC 360 Solutions conducts comprehensive employment checks to ensure all Temporary Workers meet compliance requirements specific to their roles. Full compliance is mandatory before any assignment begins.</p>  
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">2. Dedicated Compliance Manager:</span> A compliance manager is appointed to verify all checks before deploying Temporary Workers.</p>  
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">3. Compliance with NHS Employers Check Standards:</span> RHC 360 Solutions ensures that Temporary Workers for NHS Buyers comply with the following requirements:</p>
        <ul data-aos="zoom-in" className="mt-5 ml-8 list-disc text-3">
            <li className="mb-2">Identity checks</li>
            <li className="mb-2">Professional Registration and Qualification checks</li>
            <li className="mb-2">Employment History and Reference checks</li>
            <li className="mb-2">Right to Work checks</li>
            <li className="mb-2">Work health assessments</li>
            <li className="mb-2">Criminal Record checks</li>
            </ul>  
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">4. Reference to NHS Employers Check Standards:</span> For detailed information on pre-employment checks, RHC 360 Solutions refers to the NHS Employers Check Standards.</p>  
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">5. Guidance for Buyers in Scotland:</span> Buyers located in Scotland should consult the Safer Pre & Post Employment Checks, NHS Scotland.</p>
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">6. Compliance for Central Government Buyers:</span> RHC 360 Solutions ensures compliance with the Cabinet Office Baseline Personnel Security Standard for Temporary Workers supplied to Buyers in Central Government.</p>  
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">7. Compliance for Other Buyers:</span> For all other Buyers, RHC 360 Solutions guarantees compliance with the requirements specified in the Call-Off Contract provided by the Buyer.</p>  
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">8. Additional Employment Check Requirements:</span> At the award of a Call-Off Contract, Buyers can specify additional or customized employment or security check requirements. RHC 360 Solutions will conduct these checks at no extra cost to the Buyer.</p>  
        <p data-aos="zoom-in"  className="text-3 mt-5 font-bold"><span className="home-text">Safeguarding of Children and Vulnerable Groups</span></p>
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">9. Verification of Compliance:</span> RHC 360 Solutions verifies Temporary Worker compliance with the following guidance and legislation:</p>
        <ul data-aos="zoom-in" className="mt-5 ml-8 list-disc text-3">
            <li className="mb-2">Working Together to Safeguard Children</li>
            <li className="mb-2">Safeguarding Vulnerable Groups Act 2006, Section 35</li>
            <li className="mb-2">Safeguarding Vulnerable Groups Act 2006, Section 38</li>
            </ul>  
        <p data-aos="zoom-in"  className="text-3 mt-5 font-bold"><span className="home-text">English Language Competency</span></p>  
        <p data-aos="zoom-in"  className="text-3 mt-5"><span className="font-bold">10. English Language Competence:</span> RHC 360 Solutions ensures the required English language competence for Temporary Workers based on their role, guided by:</p>
        <ul data-aos="zoom-in" className="mt-5 ml-8 list-disc text-3">
            <li className="mb-2">[Professional Registration and Qualification Checks]</li>
            <li className="mb-2">[English Language Requirement for Public Sector Workers]</li>
            </ul>  
        <p data-aos="zoom-in"  className="text-3 mt-5"> Buyers must inform RHC 360 Solutions of any additional language competencies needed or variations to English language competence.</p>  

        </div>        
        {/* <div data-aos="zoom-in"  className="flex-1 w-full mt-0">
          <img src={AboutUsimg} alt="Home Banner" className="w-full md:h-[1000px] lg:h-[400px] rounded-2xl" />
        </div> */}
        </div>
        <TestimonialsPage/> 
    <div className="mx-[40px] my-[50px]">
        <JobUpdates/>
        </div>
        <Footer/>
    </>
  );
}

export default AboutUs;





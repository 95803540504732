import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobUpdates from '../component/JobUpdates';
import BackArrow from '../component/BackArrow';
import cancelIcon from '../assets/Icons/cancel.png';
import { AuthContext } from '../component/AuthContext';
import Footer from '../component/Footer';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import HeaderHome from '../component/HeaderHome';



function JobDetailsPage({ onClose }) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [resume, setResume] = useState(null);
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenn, setIsOpenn] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const { isAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    const savedRememberMe = localStorage.getItem('rememberMe');

    if (savedEmail && savedPassword && savedRememberMe === 'true') {
      setFormData({
        email: savedEmail,
        password: savedPassword,
        rememberMe: true
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      localStorage.setItem('rememberMe', checked ? 'true' : 'false');
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!formData.email) {
      errors.email = "Email is required";
      valid = false;
    }

    if (!formData.password) {
      errors.password = "Password is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmitt = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post('https://ramsy-health-backend.vercel.app/api/users/login', {
          email: formData.email,
          password: formData.password
        });

        toast.success('Sign In successful');
        login(response.data.username);

        setFormData({
          email: '',
          password: '',
          rememberMe: false
        });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          toast.error(error.response.data.msg);
        } else {
          toast.error('Error in Sign In');
        }
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  useEffect(() => {
    const fetchJobDetails = async () => {

      fetch(`https://ramsy-health-backend.vercel.app/api/CrmJobs/CrmJobsdetails?id=${id}`, {
        method: 'GET',  
      })
      .then(response => response.json())
      .then(data => {
        console.log('Positions data:', data);
        setJob(data);
      })
      .catch(error => {
        console.error('Error fetching positions:', error);
      });
    };

    fetchJobDetails();

    const appliedState = localStorage.getItem(`applied_${id}`);
    if (appliedState) {
      setIsApplied(true);
    }
  }, [id]);

  const handleChangeForm = (e) => { 
    const { name, value } = e.target;
  
    if (name === 'phoneNumber') {
      if (/^\+?\d*$/.test(value) && value.length <= 13) {
        setPhoneNumber(value);
      } else {
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create a new FormData object to handle file and form data
      const formData = new FormData();
      formData.append('fullName', fullName);
      formData.append('email', email);
      formData.append('phoneNumber', phoneNumber);
      formData.append('resume', resume); // Append the resume file
      formData.append('message', message);

      const response = await axios.post(
        'https://ramsy-health-backend.vercel.app/api/form', // Update the backend URL if necessary
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Important to use multipart for file uploads
          },
        }
      );

      console.log(response.data);

      toast.success('Your Form is submitted successfully! we will be in touch with you shortly.', {
        onClose: () => {
          setIsApplied(true);
          localStorage.setItem(`applied_${id}`, 'true');
          closePopup();
        },
      });
    } catch (error) {
      toast.error('Error submitting form. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]); // Set the selected file
  };

  const openPopup = () => setIsOpen(true);
  const openPopupp = () => setIsOpenn(true);

  const closePopup = () => {
    setIsOpen(false);
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setResume(null);
    setMessage('');
  };

  const closePopupp = () => {
    setIsOpenn(false);
  };

  if (!job) return <div>Loading...</div>;

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-[10px] mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="text-center sm:text-start">
            <h1 className="text-white text-2xl mb-4">Discover your ideal job role here.</h1>
            <div className="bg-white rounded-xl shadow-lg flex flex-col sm:flex-row items-center p-2 space-y-2 sm:space-y-0 sm:space-x-2">
              <select className="bg-transparent border-none outline-none text-gray-500 p-2 rounded-full w-full sm:w-1/4">
                <option>Select sector</option>
              </select>
              <input
                type="text"
                placeholder="Job title or Keyword"
                className="bg-transparent border-none outline-none flex-grow p-2 text-gray-700 w-full sm:w-auto"
              />
              <input
                type="text"
                placeholder="Set your location"
                className="bg-transparent border-none outline-none flex-grow p-2 text-gray-700 w-full sm:w-auto"
              />
              <button className="bg-blue-600 text-white px-6 py-2 rounded-xl hover:bg-blue-700 w-full sm:w-auto">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[30px]">
        <BackArrow />
      </div>
      <div className="p-10 mx-[25px] md:mx-[35px] xl:mx-[50px] my-[10px] w-[93%] border rounded-md shadow-md">
        <div className="flex flex-col md:flex-row items-center mb-4">
          <div>
            <h1 data-aos="fade-up" className="text-3xl font-bold">{job.job_title}</h1>
            <p data-aos="zoom-in" className="mt-3 text-xl text-gray-600">{job.employment_type}</p>
            <span className={`mt-3 text-center inline-block p-2 rounded-md ${job.job_type === 'Full-Time' ? 'bg-green-200 text-green-700' : ''} ${job.job_type === 'Part-Time' ? 'bg-red-200 text-red-700' : ''} ${job.job_type === 'Contract' ? 'bg-purple-200 text-purple-700' : ''}`}>
              {job.job_type}
            </span>
          </div>
          <div className="md:ml-auto">
          {isAuthenticated ? (
              <>
            <button
              className={`mt-4 ${isApplied ? 'bg-gray-500' : 'bg-blue-500'} text-white p-2 rounded-md`}
              onClick={isApplied ? null : openPopup}
              disabled={isApplied}
            >
              {isApplied ? 'Applied' : 'Apply Now'}
            </button>
            {isOpen && (
              <div className="z-10 fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
                <div className="bg-white p-6 rounded-lg w-[70%] md:w-[50%] h-[90%] sm:h-[70%] md:h-[70%] lg:h-[65%]">
                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-row justify-between">
                      <div className="text-[20px] flex justify-start">
                        <h1>Apply</h1>
                      </div>
                      <div className="">
                        <button className="justify-end text-gray-800 font-bold rounded" onClick={closePopup}>
                          <img src={cancelIcon} className="h-8 w-8" alt="Cancel" />
                        </button>
                      </div>
                    </div>
                    <div className="p-6">
                      <div className="mt-3 flex flex-col sm:flex-row sm:gap-8">
                        <input
                          type="text"
                          placeholder="Full Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          required
                          className="border border-gray-300 rounded px-3 py-2 w-full mb-4"
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          className="border border-gray-300 rounded px-3 py-2 w-full mb-4"
                        />
                      </div>
                      <div className="flex flex-col sm:flex-row sm:gap-8">
                      <input
                      type="tel"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={handleChangeForm}
                      className="border border-gray-300 rounded px-3 py-2 w-full mb-4"
                    />
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          placeholder="attach resume"
                          onChange={handleFileChange}
                          required
                          className="border border-gray-300 rounded px-3 py-2 w-full mb-4"
                        />
                      </div>
                      <textarea
                        placeholder="Cover letter"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        className="border border-gray-300 rounded px-3 py-2 w-full mb-4"
                      />
                      <div className="flex justify-center">
                        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white text-xs sm:text-[16px] font-bold py-2 px-4 rounded mr-2">
                          Submit Application
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            </>
            ) : (
              <>
              <button className="mt-4 bg-blue-500 text-white p-2 rounded-md"
              onClick={openPopupp}>Apply Now</button>
              {isOpenn && (
              <div className="z-10 fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
                <div className="bg-white rounded-lg md:w-[65%] lg:w-[45%] w-[85%]">
                <form className="bg-white p-12 rounded-xl shadow-md w-full" onSubmit={handleSubmitt}>
                <div className="flex flex-row justify-between">
                      <div className="w-[50%] font-bold text-[20px] flex justify-start">
                        <h1>To apply for this job you’ll
                        need to login.</h1>
                      </div>
                      <div className="">
                        <button className="justify-end text-gray-800 font-bold rounded" onClick={closePopupp}>
                          <img src={cancelIcon} className="h-8 w-8" alt="Cancel" />
                        </button>
                      </div>
                    </div>
          <div className='mt-4 flex flex-row gap-4'>
            <div className="my-4 w-full">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
            </div>
          </div>

          <div className="my-4 relative">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
              className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
              <button onClick={togglePasswordVisibility} type="button" className="focus:outline-none">
                {showPassword ? <AiFillEyeInvisible size={16} /> : <AiFillEye size={16} />}
              </button>
            </div>
            {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
          </div>

          <div className="my-4 flex items-center justify-between">
            <label>
              <input
                type="checkbox"
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
              />
              <span className='ml-3'>Save Password</span>
            </label>
            <Link to="/forgot-password" className="text-blue-500 hover:underline">
              Forgot Password?
            </Link>
          </div>

          <div className="mt-5 flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Sign in
            </button>
          </div>

          <p className="mt-4">
            Don't have an account yet? <Link to="/SignUp" className="text-blue-500">Sign Up</Link>
          </p>
        </form>
                </div>
              </div>
            )}
              </>
            )}
          </div>
        </div>
        <h1 data-aos="fade-up" className="my-10 font-bold">Description:</h1>
        <p data-aos="zoom-in" className="my-5">{job.description}</p>
        <h1 data-aos="fade-up" className="my-10 font-bold">Requirements:</h1>
        <p data-aos="zoom-in" className="mt-3 text-xl text-gray-600">{job.Experience}</p>
      </div>
      <JobUpdates className="mx-auto p-4" />
      <Footer/>
      <ToastContainer />
    </>
  );
}

export default JobDetailsPage;

import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible, AiFillInfoCircle } from 'react-icons/ai';
import logo from "../assets/Logo/logo.png";
import loginImg from "../assets/img/Login/Rectangle 4620.png";
import overlayImg from "../assets/img/Login/Rectangle 4621.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
  const { token } = useParams();
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const togglePopup = () => setShowPopup(!showPopup);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validatePassword = (password) => {
    const criteria = {
      minLength: /.{8,}/,
      upperCase: /[A-Z]/,
      lowerCase: /[a-z]/,
      number: /[0-9]/,
      specialChar: /[!@#?]/,
    };

    const failedCriteria = {};

    if (!criteria.minLength.test(password)) failedCriteria.minLength = true;
    if (!criteria.upperCase.test(password)) failedCriteria.upperCase = true;
    if (!criteria.lowerCase.test(password)) failedCriteria.lowerCase = true;
    if (!criteria.number.test(password)) failedCriteria.number = true;
    if (!criteria.specialChar.test(password)) failedCriteria.specialChar = true;

    return Object.keys(failedCriteria).length === 0 ? null : failedCriteria;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordErrors = validatePassword(formData.newPassword);
    if (formData.newPassword !== formData.confirmPassword) {
      setErrors({ password: 'Passwords do not match. Please re-enter.' });
      return;
    }

    if (passwordErrors) {
      setErrors({ ...passwordErrors });
      setShowPopup(true);
      return;
    }

    try {
      await axios.post('https://ramsy-health-backend.vercel.app/api/users/reset-password', {
        token,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword
      });
      toast.success('Password has been reset');
      setFormData({
        newPassword: '',
        confirmPassword: ''
      });
      setErrors({});
      navigate('/SignIn');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        toast.error(error.response.data.msg);
      } else {
        toast.error('Error in resetting password');
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center">
      <ToastContainer />
      <form className="bg-white px-12 py-4 rounded shadow-md w-full max-w-lg" onSubmit={handleSubmit}>
        <a href="/"><img src={logo} alt="RHC 360 Solutions" className="justify-start h-12 w-auto" /></a>
        <h2 className="mt-5 my-4 text-xl font-bold">Enter your new password here</h2>
        <div className="mb-4 relative">
          <input
            type={showPassword ? 'text' : 'password'}
            name="newPassword"
            placeholder="New Password"
            value={formData.newPassword}
            onChange={handleChange}
            required
            className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
            <button onClick={togglePasswordVisibility} type="button" className="focus:outline-none">
              {showPassword ? <AiFillEyeInvisible size={16} /> : <AiFillEye size={16} />}
            </button>
            <button onClick={togglePopup} type="button" className="focus:outline-none">
              <AiFillInfoCircle size={16} />
            </button>
            </div>
          </div>
          {showPopup && (
            <div className="relative mb-4">
            <div className="top-full right-0 mt-2 w-100 p-4 border border-gray-300 rounded shadow-lg bg-blue-50">
              <h3 className="mt-0 font-bold text-red-500">Password Instructions</h3>
              <ul className="mt-2 list-disc list-inside text-sm">
                <li className={errors.minLength ? 'font-bold text-red-500' : ''}>At least 8 characters—the longer, the better.</li>
                <li className={`${errors.upperCase ? 'font-bold text-red-500' : ''} ${errors.lowerCase ? 'font-bold text-red-500' : ''}`}>A mixture of both uppercase and lowercase letters.</li>
                <li className={errors.number ? 'font-bold text-red-500' : ''}>A mixture of letters and numbers.</li>
                <li className={errors.specialChar ? 'font-bold text-red-500' : ''}>Inclusion of at least one special character, e.g., ! @ # ?</li>
              </ul>
            </div>
          </div>
          )}
        <div className="relative mb-4">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Re-enter Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className={`hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.password && 'border-red-500'}`}
          />

            <button onClick={toggleConfirmPasswordVisibility} type="button" className="absolute inset-y-0 right-0 flex items-center pr-3 focus:outline-none">
            {showConfirmPassword ? <AiFillEyeInvisible size={16} /> : <AiFillEye size={16} />}
          </button>
          {errors.password && <p className="text-red-500 text-xs italic mt-2">{errors.password}</p>}
        
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 w-[100%] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Confirm
          </button>
        </div>
        <p className="mt-4">
          Having problem with the account? <a href="/" className="text-blue-500">Contact us</a>
        </p>
      </form>
      <div className="relative w-full max-w-md hidden lg:flex items-center justify-center">
        <div className="relative w-full h-full flex items-center justify-center">
          <img src={loginImg} alt="Login" className="object-cover rounded shadow-md w-full h-full" />
          <div className="absolute inset-0 bg-transparent">
            <img src={overlayImg} alt="Overlay" className="object-cover w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

import React, { useState } from 'react';

const DropdownSection = ({ title, children }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="mb-4 mt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-sm sm:text-xl font-bold">{title}</h2>
        <button onClick={toggleDropdown} className="focus:outline-none">
          {isDropdownOpen ? '▲' : '▼'}
        </button>
      </div>
      {isDropdownOpen && <div className="space-y-2">{children}</div>}
    </div>
  );
};

export default DropdownSection;

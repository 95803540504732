import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import logo from "../assets/Logo/logo.png";
import loginImg from "../assets/img/Login/Rectangle 4620.png";
import overlayImg from "../assets/img/Login/Rectangle 4621.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      try {
        await axios.post('https://ramsy-health-backend.vercel.app/api/users/forgot-password', { email });
        toast.success('Reset link sent to your email');
        setEmail('');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          toast.error(error.response.data.msg);
        } else {
          toast.error('Error in sending reset link');
        }
      }
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center">
      <ToastContainer />
      <form className="bg-white px-12 py-4 rounded shadow-md w-full max-w-lg" onSubmit={handleSubmit}>
        <a href="/"><img src={logo} alt="RHC 360 Solutions" className="justify-start h-28 w-40" /></a>
        <div className='mt-4 flex flex-row'>
          <Link to="/SignIn" className="focus:outline-none">
            <FaArrowLeft className="mt-1 text-[#0082DA] text-md"/>
          </Link>
          <div className="ml-3 text-[#0082DA] text-[16px]">
            <Link to="/SignIn">Back to Sign in</Link>
          </div>
        </div>

        <h2 className="mt-2 text-xl font-bold">Forgot password? Enter your email ID</h2>

        <div className='flex flex-row gap-4'>
          <div className="mt-8 my-4 w-full">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleChange}
              required
              className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <div className="mt-5 flex items-center justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Send Reset Link
          </button>
        </div>

        <p className="mt-4">
          Having problems with your account? <Link to="/ContactUs" className="text-blue-500">Contact us</Link>
        </p>
      </form>

      <div className="relative w-full max-w-md hidden lg:flex flex items-center justify-center">
        <div className="relative w-full h-full flex items-center justify-center">
          <img src={loginImg} alt="Login" className='object-cover rounded shadow-md w-full h-full' />
          <div className="absolute inset-0 bg-transparent">
            <img src={overlayImg} alt="Overlay" className="object-cover w-full h-full"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

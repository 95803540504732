import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const PrevArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} bg-gray-800 p-2 ml-6 rounded-full absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer z-10`}
    style={{ ...style, display: 'block' }}
    onClick={onClick}
  >
    {/* <FontAwesomeIcon icon={faChevronLeft} className="text-white" /> */}
  </div>
);

export const NextArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} bg-gray-800 p-2 mr-6 rounded-full absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer z-10`}
    style={{ ...style, display: 'block' }}
    onClick={onClick}
  >
    {/* <FontAwesomeIcon icon={faChevronRight} className="text-white" /> */}
  </div>
);

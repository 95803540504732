// src/components/BackArrow.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';


const BackArrow = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className="ml-8 flex items-center p-2"
    >
    <FaArrowLeft className="text-[#0082DA] text-xl mx-4" />
      <span className="text-[#0082DA] text-xs md:text-xl">Back to careers</span>
    </button>
  );
};

export default BackArrow;

import React from 'react';
import Dom from '../../assets/img/Home/Rectangle 4599.png';
import overlayImg from "../../assets/img/Login/Rectangle 4621.png";
import JobUpdates from '../../component/JobUpdates';
import Footer from '../../component/Footer';
import HeaderHome from '../../component/HeaderHome';

function Domiciliary() {

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-auto lg:mx-0 py-8 px-4 sm:px-6 lg:px-8">
  <div className="text-center md:text-start w-full lg:w-[70%] mx-auto lg:mx-0">
    <h1 className="text">Innovative Services and Solutions for a Healthier Tomorrow.</h1>
    {/* <p className="text-2">"Discover how cutting-edge technologies and patient-centered care are revolutionising healthcare services, improving outcomes, and ensuring a healthier future for all."</p> */}
  </div>
</div>

      </div>
      <div className="mt-16 mx-auto px-4 lg:px-[50px] justify-center w-full h-full">
        <div className="w-full">
          <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[24px] sm:text-[50px] md:text-[70px] lg:text-[90px] font-normal z-20'>Domiciliary Care</div>
            <img src={Dom} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
        {/* <h1 className="mt-12 font-bold text-6 mb-2"><span className="home-text"></span></h1> */}
          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Personal care</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">RHC 360 Solutions offers personal care services that always ensure your safety and wellbeing. Our well trained and qualified staff consist of professional experience and skills in aiding you with the maintenance of your daily tasks with privacy, comfort, and convenience.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-semibold">Our exceptional personal care services include:</p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2">Helping to and from bed</li>
            <li className="mb-2">Help with washing, dressing, bathing, and showering</li>
            <li className="mb-2">Oral hygiene and skin care</li>
            <li className="mb-2">Stoma and catheter care.</li>
            <li className="mb-2">Refreshing continence pads</li>
            <li className="mb-2">Aid with toileting needs</li>
            <li className="mb-2">Meal preparation and feeding</li>
            <li className="mb-2">Aid and prompting of medication</li>
        </ul>
          
          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Respite care</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">Our respite care services offer you the convenience of having our help regardless of a time limit, whether it is one hour or half a day, you can step out rest assured your family member is well cared for. We will discuss your specific concerns and requirements and offer you personalised service package that will relieve you to attend to your personal needs. Our staff will ensure the safety and wellbeing of your family member until you are ready to take over.</p>

          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">24 Hour Live-in care</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">As our name suggests; RHC 360 Solutions, our live-in care solutions are offered around the clock any day of the week. Our rotational shifts ensure efficiency of our staff members, delivering quality and uncompromised care. Live-in care depicts a dedicated carer living at your home taking turns with a relieving carer to attend to your needs.</p>
          <p data-aos="zoom-in" className="text-3 mt-8">You are free to discuss with us how you want the rotation of the carers to split, to ensure the 24-hour frame is covered. Moreover, we offer you the choice of selecting the preferred carer profile to help you feel more comfortable at your own home.</p>
          <p data-aos="zoom-in" className="text-3 mt-12">Night shifts can vary from a late start to wake-in and sleepover shifts. The charges are calculated based on a weekly care plan. This type of plan is typically referred to social services, however you also can self-fund the live-in care service by discussing with our consultants on how best we can support you.</p>

          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Palliative care</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">We have specialized teams dedicated to providing care for patients with terminal or life-threatening conditions. While some conditions may not be curable, our care focuses on enhancing the quality of life for both our clients and their loved ones.</p>

          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Our capabilities:</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12"><span className="font-bold">Qualified Nurses:</span> Our trained nurses deliver high-quality care at home.</p>
          <p data-aos="zoom-in" className="text-3 mt-12"><span className="font-bold">Personalized Care Plans:</span> Each client receives a tailored plan that respects their unique needs and preferences.</p>
          <p data-aos="zoom-in" className="text-3 mt-12"><span className="font-bold">Emotional Support:</span> We help families in discussing feelings and provide bereavement support.</p>


          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Dementia Care</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">At RHC 360 Solutions, our staff are trained to support clients at all stages of dementia, ensuring familiarity and security in care.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-semibold">Dementia Support Services include:</p>
          <p data-aos="zoom-in" className="text-3 mt-12"><span className="font-bold">Consistent Care Staff:</span> Clients are cared for by the same staff to foster comfort.</p>
          <p data-aos="zoom-in" className="text-3 mt-8"><span className="font-bold">Needs Assessment:</span> We conduct detailed assessments to create meaningful care plans.</p>
          <p data-aos="zoom-in" className="text-3 mt-12"><span className="font-bold">Specialized Training:</span> Our caregivers can handle the complexities of dementia.</p>

          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">End-of-Life Care</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">We understand the sensitivity of individuals coping with progressive or incurable illness. That is why we offer personalized end-of-life care for individuals, allowing them to spend their time at the comfort of their home with their loved ones.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-semibold">Our caring ranges from:</p>
          <p data-aos="zoom-in" className="text-3 mt-12"><span className="font-bold">Collaboration with Healthcare Teams:</span> We coordinate with NHS and hospital staff for seamless care transitions.</p>
          <p data-aos="zoom-in" className="text-3 mt-8"><span className="font-bold">Emergency Care Arrangements:</span> We can implement care quickly as needed.</p>
          <p data-aos="zoom-in" className="text-3 mt-12"><span className="font-bold">Focus on Comfort:</span> Our trained staff ensure clients are comfortable during this challenging time.</p>
          <p data-aos="zoom-in" className="text-3 mt-8"><span className="font-bold">Physical Disabilities Care:</span> We provide support for individuals with various physical disabilities, helping them with daily tasks.</p>

          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Other Conditions Supported:</span></h3>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2">Epilepsy</li>
            <li className="mb-2">Cerebral Palsy</li>
            <li className="mb-2">Multiple Sclerosis</li>
            <li className="mb-2">Stroke</li>
            <li className="mb-2">Cancer, among others.</li>
            </ul>  

          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Learning Disabilities Support</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">Our team helps children and adults with learning disabilities, helping them with everyday tasks.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-semibold">Areas of Focus:</p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2">Dyslexia</li>
            <li className="mb-2">ADHD</li>
            <li className="mb-2">Non-verbal communication.</li>
            </ul> 

          <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2"><span className="">Mental Health Care</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">We support individuals with mental health conditions, providing active assistance for both short- and long-term challenges.</p>
          
          
        </div>
      </div>
      <div className="mx-[40px] my-[100px]">
        <JobUpdates />
      </div>
      <Footer />
    </>
  );
}

export default Domiciliary;

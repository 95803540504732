import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../assets/Logo/logo.png";
import loginImg from "../assets/img/Login/Rectangle 4620.png";
import overlayImg from "../assets/img/Login/Rectangle 4621.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../component/AuthContext'; // Import AuthContext

function SignIn() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Use AuthContext

  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    const savedPassword = localStorage.getItem('password');
    const savedRememberMe = localStorage.getItem('rememberMe');

    if (savedEmail && savedPassword && savedRememberMe === 'true') {
      setFormData({
        email: savedEmail,
        password: savedPassword,
        rememberMe: true
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      localStorage.setItem('rememberMe', checked ? 'true' : 'false');
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!formData.email) {
      errors.email = "Email is required";
      valid = false;
    }

    if (!formData.password) {
      errors.password = "Password is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post('https://ramsy-health-backend.vercel.app/api/users/login', {
          email: formData.email,
          password: formData.password
        });

        toast.success('Sign In successful');
        login(response.data.username); // Set the authenticated state with username
        navigate('/');

        setFormData({
          email: '',
          password: '',
          rememberMe: false
        });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          toast.error(error.response.data.msg);
        } else {
          toast.error('Error in Sign In');
        }
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-center items-center">
        <form className="bg-white px-12 py-4 rounded shadow-md w-full max-w-lg" onSubmit={handleSubmit}>
          <a href="/"><img src={logo} alt="RHC 360 Solutions" className="justify-start h-28 w-40" /></a>
          <h2 className="mt-4 text-xl font-bold">Welcome, Sign in to RHC 360 Solutions</h2>

          <div className='flex flex-row gap-4'>
            <div className="my-4 w-full">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
            </div>
          </div>

          <div className="mb-4 relative">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
              className="hover:border-[#0082DA] border-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 space-x-2">
              <button onClick={togglePasswordVisibility} type="button" className="focus:outline-none">
                {showPassword ? <AiFillEyeInvisible size={16} /> : <AiFillEye size={16} />}
              </button>
            </div>
            {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
          </div>

          <div className="flex items-center justify-between">
            <label>
              <input
                type="checkbox"
                name="rememberMe"
                checked={formData.rememberMe}
                onChange={handleChange}
              />
              <span className='ml-3'>Save Password</span>
            </label>
            <Link to="/forgot-password" className="text-blue-500 hover:underline">
              Forgot Password?
            </Link>
          </div>

          <div className="mt-5 flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Sign in
            </button>
          </div>

          <p className="mt-4">
            Don't have an account yet? <Link to="/SignUp" className="text-blue-500">Sign Up</Link>
          </p>

          {/* <p className="text-center text-[12px] mt-4">
            OR
          </p>

          <div className="mt-4">
            <button className="bg-white border border-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full flex items-center justify-center">
              <img src="https://img.icons8.com/color/16/000000/google-logo.png" alt="Google" className="mr-2" />
              Continue with Google
            </button>
          </div> */}
        </form>

        <div className="relative w-full max-w-md hidden lg:flex flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
            <img src={loginImg} alt="Login" className='object-cover rounded shadow-md w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="object-cover w-full h-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;

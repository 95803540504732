import React from "react";

const FlipbookDownload = () => {
  const handleRedirect = () => {
    window.location.href = "https://haitchjay.com/hasee/#p=1"; // Replace with your desired URL
  };

  return (
    <div className="">
      <button
        onClick={handleRedirect}
        className="mt-6 bg-white text-[#0082DA] px-6 py-2 rounded"
      >
        RHC Brochure
      </button>
    </div>
  );
};

export default FlipbookDownload;

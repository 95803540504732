import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import Footer from '../component/Footer';
import HeaderHome from '../component/HeaderHome';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    enquiryType: '',
    message: '',
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('');

  const { name, phone, email, enquiryType, message } = formData;

  const onChange = e => {
    const { name, value } = e.target;
  
    if (name === 'phone') {
      // Validate phone number: optional + followed by digits, with max 13 characters
      if (/^\+?\d*$/.test(value) && value.length <= 13) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!phone) newErrors.phone = 'Phone number is required';
    if (!email) newErrors.email = 'Email is required';
    if (!enquiryType) newErrors.enquiryType = 'Enquiry type is required';
    if (!message) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await axios.post('https://ramsy-health-backend.vercel.app/api/contact', formData);
        toast.success('Thanks for reaching us! Contact form submitted successfully and we will be in touch with you shortly.');
  
        // Reset form data on successful submission
        setFormData({
          name: '',
          phone: '',
          email: '',
          enquiryType: '',
          message: '',
          termsAccepted: false,
        });
        setErrors({}); // Clear any previous errors
      } catch (error) {
        toast.error('Error in sending message');
      }
    }
  };
  

  return (
    <div>
      <HeaderHome />
    <div className="bg-[#0082DA]">
        <div className="mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="text-center md:text-start w-full lg:w-[70%]">
            <h1 className="text-white text mb-4">We’re here to help.</h1>
            <p className="text-white text-2 mb-8">
            Dont hesitate to reach out with any questions, we are here to help.
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto py-12 bg-[#f5f4f2]">
        <h1 data-aos="fade-up" className="mx-[40px] sm:mx-[60px] md:mx-[40px] lg:mx-[75px] text-[24px] md:text-3xl font-bold mb-8">Leave us a message</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-0">
          <form data-aos="zoom-in" onSubmit={handleSubmit} className="w-[80%] h-full md:h-[80%] lg:h-[85%] mx-auto bg-white shadow-md rounded-lg p-6 lg:p-12">
            <div className="flex flex-row mb-4 gap-4">
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Full Name"
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[#0082DA] rounded-md"
                required
              />
              {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
              <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              minLength={13}
              maxLength={13}
              onChange={onChange}
              className="w-full p-2 border-2 hover:border-[blue] rounded-md"
              required
            />

              {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
            </div>
            <div className="flex flex-row mb-4 gap-4">
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email ID"
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[#0082DA] rounded-md"
                required
              />
              {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
              <select
                name="enquiryType"
                value={enquiryType}
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[#0082DA] rounded-md"
                required
              >
                <option value="">Enquiry Type</option>
                <option value="Health Care">Health Care</option>
                <option value="Jobs">Jobs</option>
                <option value="Training">Training</option>
              </select>
              {errors.enquiryType && <p className="text-red-500 text-xs italic">{errors.enquiryType}</p>}
            </div>
            <div className="mb-4">
              <textarea
                name="message"
                value={message}
                placeholder="Enter your message here..."
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[#0082DA] rounded-md"
                rows="8"
                minLength={20}
                maxLength={100}
                required
              />
              {errors.message && <p className="text-red-500 text-xs italic">{errors.message}</p>}
            </div>
            <div className="flex flex-col xl:flex-row md:items-center md:justify-between mb-4">
          <div className="flex items-center mb-2 md:mb-0">
    <input
      type="checkbox"
      id="termsAccepted"
      name="termsAccepted"
      checked={formData.termsAccepted}
      onChange={handleChange}
      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
      required
    />
    <label htmlFor="termsAccepted" className="ml-2 text-sm text-gray-900">
      I agree to the Terms & Conditions
    </label>
  </div>
  <button
    type="submit"
    className="mt-5 xl:mt-0 w-[80%] xl:w-[30%] bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition duration-300"
  >
    Send
  </button>
</div>

            {status && <p className="text-center text-red-500 mt-2">{status}</p>}
          </form>
          <div className="w-[100%] gap-8 flex flex-col items-center">
            <div data-aos="zoom-in" className="w-[80%] bg-white rounded-lg shadow-md p-4 flex flex-col">
              <FaEnvelope className="text-blue-600 text-3xl mb-2" />
              <h3 className="text-3 font-semibold mb-2">Email us</h3>
              <p className="text-4 mb-2">
                Ask us a question by email and we will respond within a few days.
              </p>
              <a href="mailto:bookings@rhc360.com" className="text-blue-600 font-medium">
                bookings@rhc360.com
              </a>
            </div>
            <div data-aos="zoom-in" className="w-[80%] bg-white rounded-lg shadow-md p-4 flex flex-col">
              <FaPhoneAlt className="text-green-500 text-3xl mb-2" />
              <h3 className="text-3 font-semibold mb-2">Call us anytime</h3>
              <p className="text-4 mb-2">
                Call us if your question requires an immediate response.
              </p>
              <a href="tel:+442035001100" className="text-blue-600 font-medium">+44 (0)20 3500 1100</a>
            </div>
            <div data-aos="zoom-in" className="w-[80%] bg-white rounded-lg shadow-md p-4 flex flex-col">
              <FaMapMarkerAlt className="text-red-500 text-3xl mb-2" />
              <h3 className="text-3 font-semibold mb-2">Visit us</h3>
              <p className="text-4 mb-2">
                You can meet us at our office and discuss the details of your question.
              </p>
              <a
                href="https://maps.google.com/?q=422 High Street North, London E12 6RH, UK"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 font-medium"
              >
                Get Directions
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default ContactForm;

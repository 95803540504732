import React, { useState, useEffect } from 'react';
import JobUpdates from '../component/JobUpdates';
import DropdownSection from '../component/DropdownSection';
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import Footer from '../component/Footer';
import axios from 'axios';
import HeaderHome from '../component/HeaderHome';
import { Range, getTrackBackground } from 'react-range';

function JobsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [locationTerm, setLocationTerm] = useState('');
  const [sector, setSector] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedJobTypes, setSelectedJobTypes] = useState({
    Permanent: false,
    PartTime: false,
    Contract: false,
    Internship: false,
  });
  const [selectedEmploymentTypes, setEmploymentTypes] = useState({
    'Full_time': false,
    'Part_time': false,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
        fetchJobs(code);
}, []);

const fetchJobs = (code) => {
  setLoading(true);
    fetch('https://ramsy-health-backend.vercel.app/api/CrmJobs/CrmJobs', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(response => response.json())
    .then(data => {
      console.log("data",data);
        if (data.result.items) {
            setJobs(data.result.items);
            console.log('Jobs fetched:', data.result.items);
        } else {
            console.error('Error fetching jobs:', data.error);
        }
    })
    .catch(error => {
        console.error('Error fetching jobs:', error);
    })
    .finally(() => {
      setLoading(false); // Set loading to false after the request completes
    });
};


  const handleJobClick = (id) => {
    navigate(`/job/${id}`);
  };

  const handleJobTypeChange = (e) => {
    const { name, checked } = e.target;
    setSelectedJobTypes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleEmploymentTypeChange = (e) => {
    const { name, checked } = e.target;
    setEmploymentTypes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSearch = () => {
  };

  const isFilterSelected = (filter) => Object.values(filter).some((value) => value);

  const filteredJobs = jobs.filter((job) => {
    const jobNameMatch =
      job.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.employment_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.job_type.toLowerCase().includes(searchTerm.toLowerCase()) 
      const jobTypeMatch =
      Object.keys(selectedJobTypes).some((type) => selectedJobTypes[type] && job.job_type.toLowerCase().includes(type.toLowerCase())) ||
      !isFilterSelected(selectedJobTypes);
      const EmploymentTypeMatch =
      Object.keys(selectedEmploymentTypes).some((EmploymentType) => selectedEmploymentTypes[EmploymentType] && job.employment_type.toLowerCase().includes(EmploymentType.toLowerCase())) ||
      !isFilterSelected(selectedEmploymentTypes);
      const sectorMatch = sector === '' || job.Sector === sector;

    return  jobNameMatch && jobTypeMatch && EmploymentTypeMatch && sectorMatch;
  });

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-[10px] mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="text-center sm:text-start">
            <h1 className="text-white text-2xl mb-4">Discover your ideal job role here.</h1>
            <div className="bg-white rounded-xl shadow-lg flex flex-col sm:flex-row items-center p-2 space-y-2 sm:space-y-0 sm:space-x-2">
              <select
                className="bg-transparent border-none outline-none text-gray-500 p-2 rounded-full w-full sm:w-1/4"
                value={sector}
                onChange={(e) => setSector(e.target.value)}
              >
                <option value="">Select sector</option>
                <option value="Clinical">Clinical</option>
                <option value="NON Clinical">NON Clinical</option>
              </select>
              <input
                type="text"
                placeholder="Job title or Keyword"
                className="bg-transparent border-none outline-none flex-grow p-2 text-gray-700 w-full sm:w-auto"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <input
                type="text"
                placeholder="Set your location"
                className="bg-transparent border-none outline-none flex-grow p-2 text-gray-700 w-full sm:w-auto"
                value={locationTerm}
                onChange={(e) => setLocationTerm(e.target.value)}
              />
              <button
                className="bg-blue-600 text-white px-6 py-2 rounded-xl hover:bg-blue-700 w-full sm:w-auto"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="md:mx-[10px] p-4 flex flex-row lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
        <div data-aos="zoom-in" className="w-1/3 lg:w-1/4 bg-white p-4 rounded-md shadow-md">
          <DropdownSection title="Job Type">
            {['Permanent', 'PartTime', 'Contract', 'Internship'].map((type) => (
              <label key={type} className="flex items-center">
                <input
                  type="checkbox"
                  name={type}
                  checked={selectedJobTypes[type]}
                  onChange={handleJobTypeChange}
                  className="mr-2"
                />
                {type}
              </label>
            ))}
          </DropdownSection>
          <DropdownSection title="Employment Type">
            {Object.keys(selectedEmploymentTypes).map((EmploymentType) => (
              <label key={EmploymentType} className="flex items-center">
                <input
                  type="checkbox"
                  name={EmploymentType}
                  checked={selectedEmploymentTypes[EmploymentType]}
                  onChange={handleEmploymentTypeChange}
                  className="mr-2"
                />
                {EmploymentType}
              </label>
            ))}
          </DropdownSection>
        </div>
        <div className="w-full lg:w-3/4 p-4">
          <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <h2 className="text-xs sm:text-lg lg:text-xl font-bold mb-2 md:mb-0">{loading ? 'Loading jobs...' : `Showing: ${filteredJobs.length} filtered jobs`}</h2>
            <label className='text-xs sm:text-[16px]'>
              Sort by :
              <select className="p-2 rounded-md">
                <option>Past 24 hours</option>
                <option>Past 1 week</option>
                <option>Past 1 month</option>
              </select>
            </label>
          </div>
          <div data-aos="zoom-in" className="space-y-4">
          {loading ? (
              <div>Loading...</div>
            ) : (
            filteredJobs.map((job) => (
              <div
                key={job.id}
                className="flex flex-col md:flex-row bg-white p-4 rounded-md shadow-md items-center justify-between cursor-pointer"
                onClick={() => handleJobClick(job.id)}
              >
                <div className="flex items-center w-full md:w-[75%] mb-4 md:mb-0">
                  <div>
                    <h3 className="text-lg"><span className='font-bold'>JOB TITLE : </span>{job.job_title}</h3>
                    <h6 className="text-xs"><span className='font-bold'>EMPLOYMENT TYPE : </span>{job.employment_type}</h6>
                    <h3 className="text-xs"><span className='font-bold'>JOB TYPE : </span>{job.job_type}</h3>
                  </div>
                </div>
              </div>
             ))
            )}
          </div>
        </div>
      </div>
      <div className="mx-[20px] lg:mx-[50px] lg:my-[50px]">
          <h1 data-aos="fade-up" className="font-bold text-[24px] md:text-[36px] mb-2 text-center"><span className="home-text">DBS</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC 360 Solutions is dedicated to providing comprehensive support for candidates navigating the complexities of Disclosure and Barring Service (DBS) checks. Understanding the importance of these checks in ensuring the safety and welfare of vulnerable populations, RHC 360 Solutions offers tailored assistance to streamline the process.</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A DBS check is crucial for individuals seeking employment in roles that involve working with children or vulnerable adults. The process can often be daunting, with various requirements and documentation needed. RHC 360 Solutions aims to demystify this process by guiding candidates through each step, ensuring they understand what is required and how to fulfill those requirements efficiently.</p>
          <p data-aos="zoom-in" className="text-3 mt-5">One of the key services offered by RHC 360 Solutions is assistance with the processing of DBS checks. This includes helping candidates fill out the necessary forms accurately, gathering the required identification documents, and ensuring that all information is submitted correctly. By providing this support, RHC 360 Solutions minimizes the risk of delays that can occur due to errors or incomplete applications.</p>
          <p data-aos="zoom-in" className="text-3 mt-5">Additionally, RHC 360 Solutions accepts current DBS checks, which can significantly expedite the hiring process for candidates who already have an up-to-date check. This flexibility allows candidates to demonstrate their commitment to safety and compliance without undergoing the lengthy process of obtaining a new check.</p>
        </div>
        <div className="mx-[20px] lg:mx-[50px] lg:my-[50px]">
          <h1 data-aos="fade-up" className="font-bold text-[24px] md:text-[36px] mb-2 text-center"><span className="home-text">Immigration Check </span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">The UK is transitioning to a digital immigration system featuring eVisas, which serve as online records of your immigration status. To access your eVisa and share your status with employers, landlords, or other relevant parties, you'll need to create a UK Visas and Immigration (UKVI) account.</p>
        </div>
        <div className="mx-[20px] lg:mx-[50px] lg:my-[50px]">
          <h1 data-aos="fade-up" className="font-bold text-[24px] md:text-[36px] mb-2 text-center"><span className="home-text">Proving Your Status</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">You can utilize the UKVI online service to view and prove your immigration status. This process involves generating a share code that you can provide to employers or landlords, allowing them to verify your right to work or rent in the UK.</p>
          <p data-aos="zoom-in" className="text-3 mt-5">These measures are designed to ensure compliance with UK immigration laws and enhance border security.</p>
        </div>
        <div className="mx-[20px] lg:mx-[50px] lg:my-[50px]">
          <h1 data-aos="fade-up" className="font-bold text-[24px] md:text-[36px] mb-2 text-center"><span className="home-text">Right to work check</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">The UK right to work check is a crucial process for employers to verify that potential employees have the legal authorization to work in the country. Employers are required to check an individual's immigration status before hiring them, ensuring compliance with UK immigration laws. This check can be conducted using various methods, including reviewing original documents, utilizing the online UK Visas and Immigration (UKVI) service, or generating a share code that provides access to the individual's immigration status. By carrying out these checks, employers help prevent illegal working and contribute to the overall integrity of the UK labor market.</p>
        </div>

      <JobUpdates className="mx-auto p-4" />
      <Footer />
    </>
  );
}

export default JobsPage;

import React from 'react';
import NonCli from '../../assets/img/Home/Rectangle 4611.png';
import overlayImg from "../../assets/img/Login/Rectangle 4621.png";
import JobUpdates from '../../component/JobUpdates';
import Footer from '../../component/Footer';
import HeaderHome from '../../component/HeaderHome';
import { useNavigate } from 'react-router-dom';
import tableImage from "../../../src/assets/img/Services/bus-prof-table.jpg";
import Admin from "../../../src/assets/img/Services/admin.jpg";
import IT from "../../../src/assets/img/Services/IT.jpg";
import Ancillary from "../../../src/assets/img/Services/ancillary.jpg";


function NonClinical() {
    const navigate = useNavigate();

    const handleClickNonClinicalPermanent = () => {
        navigate('/JobsPage?Sector=NON Clinical&JobType=Permanent');
        window.scrollTo(0, 0);
      };

    const handleClickNonClinicalTemporary = () => {
        navigate('/JobsPage?Sector=NON Clinical&JobType=Contract');
        window.scrollTo(0, 0);
      };

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-auto lg:mx-0 py-8 px-4 sm:px-6 lg:px-8">
  <div className="text-center md:text-start w-full lg:w-[70%] mx-auto lg:mx-0">
    <h1 className="text">Innovative Services and Solutions for a Healthier Tomorrow.</h1>
    {/* <p className="text-2">"Discover how cutting-edge technologies and patient-centered care are revolutionising healthcare services, improving outcomes, and ensuring a healthier future for all."</p> */}
  </div>
</div>

      </div>
      <div className="mt-16 mx-auto px-4 lg:px-[50px] justify-center w-full h-full">
        <div className="w-full text-center">
          <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[24px] sm:text-[50px] md:text-[70px] lg:text-[90px] font-normal z-20'>Non-Clinical</div>
            <img src={NonCli} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
        <h3 data-aos="fade-up" className="mt-12 font-bold text-5 mb-2 text-center"><span className="home-text">NHS WORKFORCE ALLIANCE  - NON CLINICAL FRAMEWORK</span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12 text-center">As a trusted partner on the NHS WFA Non-Clinical framework, we proudly provide flexible workers across essential staff groups, including Admin & Clerical, IT Professionals, Estates, Facilities Management, and Ancillary Staff. Our extensive network of dedicated professionals ensures that your staffing needs are met promptly and efficiently, allowing for seamless operations and uninterrupted patient care.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-bold text-center"><span className="home-text">Lot 1 Admin & Clerical</span></p>
          <div className="mt-8 w-full h-auto sm:w-3/4 sm:h-auto md:w-1/2 lg:w-2/3 mx-auto">
        <img src={Admin} alt="Admin" className="w-full h-auto object-cover" />
        </div>
          <p data-aos="zoom-in" className="text-3 mt-16 font-bold text-center"><span className="home-text">Lot 3 IT Professionals</span></p>
          <div className="mt-8 w-full h-auto sm:w-3/4 sm:h-auto md:w-1/2 lg:w-2/3 mx-auto">
          <img src={IT} alt="IT" className="w-full h-auto object-cover" />
          </div>
          <p data-aos="zoom-in" className="text-3 mt-16 font-bold text-center"><span className="home-text">Lot 6 Estates, Facilities Management, and Ancillary Staff</span></p>
          <div className="mt-8 w-full h-auto sm:w-3/4 sm:h-auto md:w-1/2 lg:w-2/3 mx-auto">
          <img src={Ancillary} alt="Ancillary" className="w-full h-auto object-cover" />
        </div>
          <div className="mt-16">
              <img src={tableImage} alt="table" className="w-full h-full" />
            </div>
          {/* <div data-aos="zoom-in" className='mt-12'>
        <button 
        onClick={handleClickNonClinicalPermanent} 
        className="bg-[#0082DA] text-2 font-bold py-2 px-5 lg:py-3 lg:px-6 rounded">
        Permanent Non-Clinical Jobs
        </button>
        <div data-aos="zoom-in" className='mt-12'>
        <button 
        onClick={handleClickNonClinicalTemporary} 
        className="bg-[#0082DA] text-2 font-bold py-2 px-5 lg:py-3 lg:px-6 rounded">
        Temporary Non-Clinical Jobs
        </button>
        </div>
        </div> */}
        </div>
        {/* <div className="mt-16 w-full text-center">
        <h3 data-aos="fade-up" className="font-bold text-5 mb-2"><span className=""></span></h3>
          <p data-aos="zoom-in" className="text-3 mt-12">As a proud and preferred supplier of the Nonclinical framework provided by the Crown Commercial Service, RHC 360 Solutions provides extensive support towards the nonclinical staffing requirements of the NHS, Care Homes and other organisations. Our candidates range from administrative, clerical, IT, hospitality, catering, maintenance and logistics related backgrounds, with over two years experience in clinical or nonclinical settings.</p>          
        </div> */}
      </div>
      <div className="mx-[40px] my-[100px]">
        <JobUpdates />
      </div>
      <Footer />
    </>
  );
}

export default NonClinical;

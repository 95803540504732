import React, { useState } from 'react';
import leftIcon from '../assets/Icons/Group 8465.png';
import rightIcon from '../assets/Icons/Group 8464.png';
import homeVacancybg from '../assets/img/Home/Rectangle 4600.png';

const Carousel = ({ galleryimgs }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryimgs.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + galleryimgs.length) % galleryimgs.length);
  };

  return (
    <div className="relative z-10">
  <div className="flex flex-col lg:flex-row items-center justify-center relative">
    <div className="my-[20px] lg:my-[50px] bg-cover bg-center w-full flex flex-col items-center justify-center z-20">
      <div className="mx-auto text-[#FFFFFF] text-center relative z-20">
        <p data-aos="fade-up" className="text-[18px]">Gallery</p>
        <p data-aos="zoom-in" className="mt-5 text-[24px] lg:text-[36px]">Capturing Moments of Healing, Care, and Hope</p>
        <div data-aos="zoom-in" className="my-10 relative w-full mx-auto overflow-hidden z-20">
          <div
            className="my-5 mx-[100px] md:mx-[200px] lg:mx-[200px] flex transition-transform duration-1000"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {galleryimgs.map((img, index) => (
              <div key={index} className="w-full flex-shrink-0 transition-transform duration-500"
                   style={{ transform: `scale(${index === currentIndex ? 1 : 0.80})` }}>
                <img
                  className="object-cover w-full h-[200px] lg:h-[500px]"
                  src={img}
                  alt={`Slide ${index}`}
                />
              </div>
            ))}
          </div>
          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white px-[100px] md:px-[200px]"
          >
            <img src={leftIcon} alt="Previous Slide" className='h-8 w-8 md:h-12 md:w-12 lg:h-full lg:w-full'/>
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 text-white px-[100px] md:px-[200px]"
          >
            <img src={rightIcon} alt="Next Slide" className='h-8 w-8 md:h-12 md:w-12 lg:h-full lg:w-full' />
          </button>
        </div>
      </div>
    </div>
  </div>
  <img
    className="absolute top-0 left-0 w-full h-full z-0 object-cover"
    src={homeVacancybg}
    alt="Overlay Image"
  />
</div>



  );
};

export default Carousel;

import React from 'react';
import BlogImg from "../assets/img/Blog/Rectangle 4622.png";
import Footer from '../component/Footer';
import HeaderHome from '../component/HeaderHome';
import JobUpdates from '../component/JobUpdates';

const Blog = () => {

  return (
    <>
      <HeaderHome />
      <div className="bg-[#0082DA]">
        <div className="md:mx-[10px] mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <div className="my-0 text-center md:text-start w-full lg:w-[70%]">
          <h1 className="text">Frequently Asked Questions (FAQs)</h1>
            {/* <h1 className="text mb-4">Navigating Life in a Care Home: Insights and Stories</h1>
            <p className="text-2">Welcome to our blog, where we share heartwarming stories, expert advice, and essential tips for life in a care home. From health and wellness to personal anecdotes, we're here to support you and your loved ones on this journey of care and connection.</p> */}
          </div>
        </div>
      </div>
      <div className="my-[70px] mx-[50px] shadow-lg rounded-xl flex flex-col justify-center">
        <div className="mt-0 mx-auto px-8 py-8">
        <p data-aos="zoom-in" className="text-5 mt-0 text-center"><span className="home-text font-extrabold">Frequently Asked Questions</span></p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">Q: How much work is available?</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A: There are plenty of opportunities! As a partner in the National Clinical Staffing Framework, we offer assignments throughout the UK for Nurses, Allied Health, and Healthcare Professionals in various NHS Trusts. We also work with top private healthcare organizations and blue-chip companies across the country.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-extrabold text-left">Q: Do you offer longer placements?</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A: Yes, we do! We can set up fixed-term assignments that last anywhere from 1 to 12 months. If you're looking for a long-term position, we'll help you find the right job that fits your needs.</p>
          <p data-aos="zoom-in" className="text-3 mt-8 font-extrabold text-left">Q: How much will I get paid?</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A: In the current economic climate, competitive pay is essential. We provide excellent rates that depend on your role, chosen shifts, and the client you work with. During your interview, we will clearly explain the pay rates for all shift patterns before confirming any assignment.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">Q: How will I get paid?</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A:You have options! You can choose to receive payments through PAYE or via an umbrella company. We ensure timely payments, so shifts worked in one week are paid the following Friday.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">Q: What’s an umbrella company?</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A: An umbrella company serves as an intermediary for processing payroll for temporary workers. They manage PAYE, National Insurance contributions, and holiday pay, complying with the National Minimum Wage. Unlike traditional employers, they do charge for these services.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">Q: How many hours am I expected to work?</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A:The choice is yours! With a variety of clients, we offer a flexible schedule. Just let us know your availability, and we’ll find shifts that work for you.</p>
          <p data-aos="zoom-in" className="text-3 mt-12 font-extrabold text-left">Q: Do I have to commit to a set number of hours?</p>
          <p data-aos="zoom-in" className="text-3 mt-5">A: No commitment is necessary! Our flexible approach allows you to choose shift patterns that align with your lifestyle and commitments, giving you the freedom to be your own boss.</p>
      </div>
      </div>
      <div className="mx-[40px] my-[50px]">
        <JobUpdates/>
        </div>
      <Footer />
    </>
  );
};

export default Blog;

import React, { useState } from 'react';
import Jobsimg from '../assets/img/Jobs/image.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobUpdates = () => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);  // Added loading state

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(value));
  };

  const handleSubscribe = async () => {
    if (email.trim() === '') {
      toast.error('The email field should not be empty.');
      return;
    }

    if (!isValid) {
      toast.error('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);  // Start loading state

    try {
      const response = await axios.post('https://ramsy-health-backend.vercel.app/api/subscriptions', { email });
      toast.success(response.data.message);
      setEmail('');  // Clear the email field on success
    } catch (error) {
      toast.error(error.response?.data?.message || 'An error occurred.');
    } finally {
      setIsLoading(false);  // End loading state
    }
  };

  return (
    <div data-aos="zoom-in" className="mx-4 md:mx-[10px] my-5 bg-[#0082DA] text-white p-4 md:p-8 rounded-xl">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="w-full md:w-[30%] mb-4 md:mb-0">
          <h2 className="text-xl font-bold text-center md:text-start">Get latest job updates</h2>
          <p className="mt-5 text-sm text-center md:text-start">Stay ahead in your career! Sign up for the latest job alerts and never miss an opportunity.</p>
        </div>
        <div className="w-full md:w-auto bg-white rounded-xl shadow-lg flex flex-col md:flex-row items-center p-2 mb-4 md:mb-0">
          <input
            type="email"
            placeholder="Enter email address"
            value={email}
            onChange={handleChange}
            className={`bg-transparent border-none outline-none flex-grow p-2 text-center md:text-start text-gray-700 ${isValid ? '' : 'border-red-500'}`}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            required
          />
          <button
            onClick={handleSubscribe}
            className={`w-full md:w-auto bg-blue-600 text-white px-6 py-2 rounded-xl hover:bg-blue-700 mt-2 md:mt-0 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isLoading}  // Disable button when loading
          >
            {isLoading ? 'Subscribing...' : 'Subscribe'}
          </button>
        </div>
        <div className="w-full md:w-[30%]">
          <img src={Jobsimg} alt="Job updates" className="w-full h-[400px] md:h-auto" />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default JobUpdates;

import React, { useEffect }  from 'react';
import { BrowserRouter as HashRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import HomePage from './pages/Homepage';
import JobsPage from './pages/JobsPage';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
// import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Blog from './pages/Blog';
import ModernSlavery from "./pages/ModernSlavery";
import JobDetailsPage from './pages/JobDetailsPage';
import { AuthProvider } from './component/AuthContext';
// import ServiceDetail from './pages/ServiceDetail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import CarbonFootPrint from './pages/CarbonFootPrint';
import JobspageInternational from './pages/JobspageInternational';
import CursorIcon from './component/CursorIcon';
import Clinical from './pages/services/Clinical';
import NonClinical from './pages/services/NonClinical';
import Domiciliary from './pages/services/Domicialliary';
import SupportedLiving from './pages/services/SupportedLiving';
import InternationalRecruitment from './pages/services/InternationalRecruitment';
import Trainings from './pages/services/Trainings';
import './css/Main.css';
import AnimationProvider from './component/AnimationProvider';

function App() {
    
    return (
        <AuthProvider>
        <HashRouter>
            <div>
            <AnimationProvider>
            </AnimationProvider>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/JobsPage" element={<JobsPage />} />
                    <Route exact path="/SignUp" element={<SignUp/>} />
                    <Route exact path="/SignIn" element={<SignIn />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword/>} />
                    <Route exact path="/reset-password/:token" element={<ResetPassword/>} />
                    {/* <Route exact path="/Services" element={<Services />} /> */}
                    <Route exact path="/AboutUs" element={<AboutUs />} />
                    <Route exact path="/ContactUs" element={<ContactUs />} />
                    <Route exact path="/Blog" element={<Blog />} />
                    <Route exact path="/ModernSlavery" element={<ModernSlavery />} />
                    <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                    <Route exact path="/TermsAndConditions" element={<TermsAndConditions />} />
                    <Route exact path="/CarbonFootPrint" element={<CarbonFootPrint />} />
                    <Route exact path="/JobspageInternational" element={<JobspageInternational />} />
                    <Route path="/job/:id" element={<JobDetailsPage />} />
                    {/* <Route path="/service/:title" element={<ServiceDetail/>} />                     */}
                    <Route exact path="/Services/Clinical" element={<Clinical />} />
                    <Route exact path="/Services/NonClinical" element={<NonClinical />} />
                    <Route exact path="/Services/Domiciliary" element={<Domiciliary />} />
                    <Route exact path="/Services/SupportedLiving" element={<SupportedLiving />} />
                    <Route exact path="/Services/InternationalRecruitment" element={<InternationalRecruitment />} />
                    <Route exact path="/Services/Trainings" element={<Trainings />} />

                    </Routes>
                    {/* <CursorIcon/>    */}
            </div>
        </HashRouter>
        </AuthProvider>
    );
}

export default App;

import React, {useState, useEffect} from 'react';
import homeImg from '../assets/img/Home/Group 8434.png';
import homeVacancy from '../assets/img/Home/Rectangle 4599.png';
import homeVacancybg from '../assets/img/Home/Rectangle 4600.png';
import homeVacancyimg from '../assets/img/Home/Group 8497.png';
import aboutImg from '../assets/img/Home/Frame 838.png';
import midimg from '../assets/img/Home/Rectangle 4611.png';
import servicesimg from '../assets/img/Home/Group 8480.png';
import galleryimg from '../assets/img/Home/Rectangle 4606.png';
import call from '../assets/Icons/call.png';
import mail from '../assets/Icons/mail.png';
import service from '../assets/img/Home/Group 8436.png';
import service1 from '../assets/Icons/Group 8436.png';
import leftIcon from '../assets/Icons/Group 8465.png';
import rightIcon from '../assets/Icons/Group 8464.png';
import Carousel from '../component/Carousel';
import '../css/Main.css';
import TestimonialsPage from '../component/Testimonials';
import Footer from '../component/Footer';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import HeaderHome from '../component/HeaderHome';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PrevArrow, NextArrow } from '../component/Arrows';
import Splitting from 'splitting';
import ScrollOut from 'scroll-out';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import Banner from "../../src/assets/img/Home/clinical-banner-.jpg";
import Banner1 from "../../src/assets/img/Home/domcare-banner.jpg";
import Banner2 from "../../src/assets/img/Home/non-clinical-banner-1.jpg";
import Banner3 from "../../src/assets/img/Home/non-clinical-banner-2.jpg";
import Gallery from "../../src/assets/img/Home/gallery-clinical-teams-1.jpg";
import Gallery1 from "../../src/assets/img/Home/gallery-clinical-teams-2.jpg";
import Gallery2 from "../../src/assets/img/Home/gallery-lifestyle-1.jpg";
import Gallery3 from "../../src/assets/img/Home/gallery-lifestyle-2.jpg";
import Gallery4 from "../../src/assets/img/Home/gallery-work-setting-1.jpg";
import Gallery5 from "../../src/assets/img/Home/gallery-work-setting-2.jpg";
import Gallery6 from "../../src/assets/img/Home/gallery-work-teams-2.jpg";
import Gallery7 from "../../src/assets/img/Home/gallery-work-teams.jpg";

const HomePage = () => {

    const galleryImages = [Gallery, Gallery1 , Gallery2 ,Gallery3 ,Gallery4, Gallery5, Gallery6 , Gallery7 /* other images */];

    const [formData, setFormData] = useState({
      name: '',
      phone: '',
      email: '',
      enquiryType: '',
      message: '',
    });
    const [errors, setErrors] = useState({});
  
    const { name, phone, email, enquiryType, message } = formData;
  
    const onChange = e => {
      const { name, value } = e.target;
    
      if (name === 'phone') {
        // Validate phone number: optional + followed by digits, with max 13 characters
        if (/^\+?\d*$/.test(value) && value.length <= 13) {
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
  
  
    const validateForm = () => {
      const newErrors = {};
      if (!name) newErrors.name = 'Name is required';
      if (!phone) newErrors.phone = 'Phone number is required';
      if (!email) newErrors.email = 'Email is required';
      if (!enquiryType) newErrors.enquiryType = 'Enquiry type is required';
      if (!message) newErrors.message = 'Message is required';
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = async e => {
      e.preventDefault();
      if (validateForm()) {
        try {
          await axios.post('https://ramsy-health-backend.vercel.app/api/contact', formData);
          toast.success(
            'Thanks for reaching us! Contact form submitted successfully and we will be in touch with you shortly.'
          );
          setFormData({
            name: '',
            phone: '',
            email: '',
            enquiryType: '',
            message: '',
          });
          setErrors({});
        } catch (error) {
          toast.error('Error in submitting form');
        }
      }
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
  
    const slides = [
      { id: 1, 
        url: Banner
      },
      { id: 2, 
        url: Banner1 
      },
      { id: 3, 
        url: Banner2 
      },
      { id: 4, 
        url: Banner3 
      },
    ];


    useEffect(() => {
      Splitting();
  
      const textElements = document.querySelectorAll('.home-text3 .char');
      textElements.forEach((char, index) => {
        char.style.setProperty('--delay', `${index * 0.03}s`);
      });
  
      ScrollOut({
        targets: '.home-text3',
        onShown: function (el) {
          el.classList.add('is-visible');
        },
        onHidden: function (el) {
          el.classList.remove('is-visible');
        }
      });
    }, []);
  

  return (
    <section className='bg-[#F4FEFF]'>
      <HeaderHome/>        
      <div data-aos="zoom-in" className="py-2">
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.id}>
            <img src={slide.url} alt={`Slide ${slide.id}`} className="w-full h-[200px] sm:h-[300px] lg:h-[600px]" style={{ transform: 'none' }}
        onMouseEnter={(e) => e.currentTarget.style.transform = 'none'} 
        onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}/>
          </div>
        ))} 
      </Slider>
    </div>
      <div className="my-8 relative mx-auto px-4 lg:px-[50px] flex flex-col lg:flex-row items-center justify-center w-full h-full">
        <div className="flex-1 w-full text-center lg:text-left">
          <h1 className="font-bold mb-2 text-[30px] md:text-[60px] home-text3 home-text3--fading is-hidden" data-scroll data-splitting>RHC 360 Solutions</h1>
          <h1 className="font-bold mb-2 text-[30px] md:text-[60px] home-text3 home-text3--fading is-hidden" data-scroll data-splitting>Caring For a Better Future</h1>
        </div>
        <div data-aos="zoom-in" className="flex-1 w-full mt-5 lg:mt-0">
          <img src={homeImg} alt="Home Banner" className="h-full w-full" />
        </div>
      </div>
      <div className="mx-[50px] my-[0px]">
          <h4 data-aos="fade-up" className="font-bold mb-2 home-text2">ABOUT US</h4> 
          <h1 data-aos="zoom-in" className="font-bold text-5 mb-2">Who <span className="home-text home-text--fading is-hidden">We </span>Are</h1>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC 360 Solutions is a dedicated recruitment service provider to the UK’s NHS and private healthcare service providers. Our experience in the industry and qualified and highly skilled clinical and nonclinical staff, has enabled our partners to deliver exceptional patient care services while maintaining consistency during crucial times. </p>
          <p data-aos="zoom-in" className="text-3 mt-5">We have prioritised the need to acquire knowledge and insights of the unique challenges and urgent requirements of the healthcare sector, which has enabled us to grow and become a trusted leader in the healthcare industry. </p>
          <p data-aos="zoom-in" className="text-3 mt-5">Our mission is to be a strategic partner to the UK’s healthcare sector by developing tailored workforce solutions that assist our clients in achieving the best results when hiring healthcare professionals. </p>
          <p data-aos="zoom-in" className="text-3 mt-5">We consider commitment to quality as a key pillar of our longstanding success and has proven to be so by the multiple frameworks we have been accredited with by the Crown Commercial Services. This is a firm testament to our commitment to the highest standards of healthcare service delivery and our credibility as a trusted company. </p>
          <p data-aos="zoom-in" className="text-3 mt-5">The expert round the clock solutions provided by us is not devised by a one-size-fits all strategy, instead we ensure the dynamic needs of each client is identified with a one to one approach and attended to by our experienced client managers who maintain consistent communication and effective service delivery. This allows us to prioritise client needs individually and ensure their challenges are not neglected. </p>
          <p data-aos="zoom-in" className="text-3 mt-5">Our support extends further beyond the supply of staffing services; we undertake the in-house HR processes by providing continuous guidance and support in candidate screening, recruitment, compliance checks, hiring, performance management and retention. </p>
          <p data-aos="zoom-in" className="text-3 mt-5">By combining our expertise, network and unwavering commitment to quality, we aim to be an invaluable asset in helping you navigate the challenges and dynamics of the UK healthcare sector while simplifying the complex landscape of healthcare staffing. </p>

        <h1 data-aos="fade-up" className="font-bold text-5 mb-2 mt-8"><span className="home-text home-text--fading is-hidden">Our Clients</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">We are a proud partner of multiple NHS Trusts, Care Homes, Healthcare logistics, transport services, educational institutions and hospitality service-related institutions. The ever-expanding network of partners is a result of our commitment towards the consistent provision of qualified and experienced healthcare professionals. Whether you require temporary nurses, locum doctors, or allied health professionals, we will provide our support services to develop a tailored strategy that delivers the right staff at the right time. Moreover, our consultative approach enables us to address your specific workforce needs from skills gaps, to shift patterns to budget constraints</p>

          <h1 data-aos="fade-up" className="font-bold text-5 mb-2 mt-8"><span className="home-text home-text--fading is-hidden">Our Employees</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC 360 Solutions consists of a highly trained team of recruitment consultants who have substantial experience in working with the UK NHS recruitment frameworks. Our consultants regularly stay up-to-date with the latest developments in healthcare recruitment, including changes in legislation and best practices. This commitment to ongoing learning and development ensures that we are always at the forefront of industry trends, allowing us to provide the highest quality service to our clients and candidates.</p>

          <h1 data-aos="fade-up" className="font-bold text-5 mb-2 mt-8"><span className="home-text home-text--fading is-hidden">Enquiry</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">Dont hesitate to reach out with any questions, we are here to help.</p>

          <h1 data-aos="fade-up" className="font-bold text-5 mb-2 mt-8"><span className="home-text home-text--fading is-hidden">Candidates</span></h1>
          <p data-aos="zoom-in" className="text-3 mt-5">“We believe in equality and diversity”</p>
          <p data-aos="zoom-in" className="text-3 mt-5">At RHC 360 Solutions, we believe in creating a safe and supportive environment and a friendly community of healthcare professionals who are committed to delivering a caring and compassionate service. Our vast and diverse community of candidates have the ability to connect with patients and peers to make a lasting impact on patient wellbeing and workplace wellbeing. At RHC 360 Solutions we ensure our candidates enjoy a well balanced work-and-home lifestyle without the pressure of excessive work, scarce work or u paid work.</p>
          <p data-aos="zoom-in" className="text-3 mt-5">Our benefits also include:</p>
          <ul data-aos="zoom-in" className="mt-5 ml-5 list-disc text-3">
            <li className="mb-2">Flexible hours</li>
            <li className="mb-2">Extensive mobility </li>
            <li className="mb-2">Competitive pay rates</li>
            <li className="mb-2">No administrative fees</li>
            <li className="mb-2">Weekly and timely payments</li>
            <li className="mb-2">Immediate jobs</li>
            <li className="mb-2">Permanent or temporary work</li>
            <li className="mb-2">Career guidance and development training </li>
            <li className="mb-2">Fast track compliance</li>
            <li className="mb-2">Choice of work locations</li>
            <li className="mb-2">Skills assessments</li>            
            </ul>
          </div>


      <div className="my-5 lg:px-[50px] relative bg-transparent">
        <img
          className="absolute inset-0 w-full h-full object-cover z-0"
          src={homeVacancy}
          alt="Vacancy Background"
        />
        <div className="relative container mx-auto px-4 py-10 z-10">
          <div className="flex flex-col lg:flex-row items-center justify-center">
            <div className="flex-1 w-full text-center lg:text-left">
              <h1 data-aos="fade-up" className="font-bold text mb-2">Join Our Team, View our current openings now!</h1>
              <p data-aos="zoom-in" className="text-1 mt-5">Exciting Opportunities Await! Be Part of a Dynamic Community Dedicated to Transforming Lives and Shaping the Future of Healthcare</p>
              <div data-aos="zoom-in" className='mt-12'>
              <a href='/JobsPage' target="blank" className="bg-white home-text2 font-bold py-3 px-4 lg:py-5 lg:px-5 rounded">View all Vacancies</a>
              </div>
            </div>
            <div data-aos="zoom-in" className="flex-1 w-full mt-5 lg:mt-0">
              <img src={homeVacancyimg} alt="Vacancy Image" className="w-full" />
            </div>
          </div>
        </div>
        <img
          className="absolute top-0 left-0 w-full h-full z-0"
          src={homeVacancybg}
          alt="Overlay Image"
        />
      </div>

      <div className="mx-[50px] my-[80px]">
          <h4 data-aos="fade-up" className="font-bold mb-2 home-text2">Services</h4> 
          <h1 data-aos="zoom-in" className="font-bold text-5 mb-2">Range of<span className="home-text home-text--fading is-hidden"> Health Care </span>Services</h1>
          <p data-aos="zoom-in" className="text-3 mt-5">RHC 360 Solutions provides a countrywide healthcare recruitment service to assist the UK NHS and care homes in identifying the best and qualified professionals to care for their patients in need. As a registered partner of clinical and non-clinical frameworks, RHC takes pride and responsibility in ensuring the acquisition of healthcare professionals are consistently aligned with the expected standards of the UK healthcare industry.</p>
          <div data-aos="zoom-in" className='mt-8'>
        <a href="/Services/Clinical" target="blank" className="bg-[#FFFFFF] font-bold py-3 px-4 lg:py-3 lg:px-5 rounded" style={{color:"#0082DA", fontSize: "18px", borderColor:"#0082DA", borderWidth:"2px"}}>View More Services</a>  
        </div>
        </div>
      <div className="relative my-12 bg-transparent">
  <div className="relative z-10 mx-12 flex flex-col items-center justify-center lg:flex-row">
    <div className="my-12 w-full flex flex-col items-center justify-center bg-cover bg-center">
      <div className="mx-auto px-4">
        <div className="my-5 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 lg:gap-32">
          <div className="p-4">
            <h2 data-aos="fade-up" className="mb-2 font-bold text-big">200+</h2>
            <p data-aos="zoom-in" className="text-small">Carers</p>
          </div>
          <div className="p-4">
            <h2 data-aos="fade-up" className="mb-2 font-bold text-big">900+</h2>
            <p data-aos="zoom-in" className="text-small">Happy Clients</p>
          </div>
          <div className="p-4">
            <h2 data-aos="fade-up" className="mb-2 font-bold text-big">100+</h2>
            <p data-aos="zoom-in" className="text-small">Medical Experts</p>
          </div>
          <div className="p-4">
            <h2 data-aos="fade-up" className="mb-2 font-bold text-big">15+</h2>
            <p data-aos="zoom-in" className="text-small">Registered with CQC</p>
          </div>
        </div>
        <div data-aos="zoom-in" className="my-5 w-full h-full">
          <img src={midimg} className="w-full h-full" alt="Mid Image" />
        </div>
      </div>
    </div>
  </div>
  <img
    className="absolute top-0 left-0 w-full h-full z-0 object-cover"
    src={homeVacancybg}
    alt="Overlay Image"
  />
</div>
      <TestimonialsPage/> 
    <Carousel 
    galleryimgs={galleryImages}
    homeVacancybg={homeVacancybg}
    leftIcon={leftIcon}
    rightIcon={rightIcon}
  />    
        <div className="mt-10 mx-auto px-4 lg:px-[50px] flex flex-col lg:flex-row items-center justify-center w-full h-full">
        <div className="flex-1 w-full text-center lg:text-left">
        <p data-aos="fade-up" className="font-bold mb-2 home-text2">Contact Us</p> 
        <p data-aos="zoom-in" className="font-semibold mb-6 text-5"><span className='home-text'>Get in touch</span> to book your appointment</p>
        <p data-aos="zoom-in" className="mb-6 text-3">"Don't hesitate to reach out with any questions, we are here to help."</p>
        <div className="flex justify-center lg:justify-start">
      <a href="tel:+442035001100" className="flex items-center space-x-4">
        <img src={call} alt="Call Icon" />
        <p data-aos="zoom-in" className='text-3'>+44 (0)20 3500 1100</p>
      </a>
      </div>
      <div className="ml-[55px] lg:ml-[0px] flex justify-around lg:justify-start">
      <a href="mailto:bookings@rhc360.com" class="mt-5 flex items-center space-x-4">
        <img src={mail} alt="Mail Icon" />
        <p data-aos="zoom-in" className='text-3'>bookings@rhc360.com</p>
      </a>
      </div>
        </div>
        <div data-aos="zoom-in" className="flex-1 w-full mt-5 lg:mt-0">
        <div className="flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
      <form onSubmit={handleSubmit} className="w-[100%] h-full md:h-[60%] mx-auto bg-white rounded-lg p-12">
            <div className="mb-4">
              <input
                type="text"
                name="name"
                value={name}
                placeholder="Full Name"
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[blue] rounded-md"
                required
              />
              {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
              </div>
              <div className="mb-4">
              <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              minLength={13}
              maxLength={13}
              onChange={onChange}
              className="w-full p-2 border-2 hover:border-[blue] rounded-md"
              required
            />
              {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
              </div>
              <div className="mb-4">
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email ID"
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[blue] rounded-md"
                required
              />
              {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
              </div>
              <div className="mb-4">
              <select
                name="enquiryType"
                value={enquiryType}
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[blue] rounded-md"
                required
              >
                <option value="">Enquiry Type</option>
                <option value="Health Care">Health Care</option>
                <option value="Jobs">Jobs</option>
                <option value="Training">Training</option>
              </select>
              {errors.enquiryType && <p className="text-red-500 text-xs italic">{errors.enquiryType}</p>}
            </div>
            <div className="mb-4">
              <textarea
                name="message"
                value={message}
                placeholder="Enter your message here..."
                onChange={onChange}
                className="w-full p-2 border-2 hover:border-[blue] rounded-md"
                rows="4"
                required
              />
              {errors.message && <p className="text-red-500 text-xs italic">{errors.message}</p>}
            </div>
  <button
    type="submit"
    className="w-[100%] bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 transition duration-300"
  >
    Get in Touch
  </button>
</form>
      </div>
    </div>        
    </div>
      </div>
      <Footer/>
      <ToastContainer />      
    </section>
  );
}

export default HomePage;

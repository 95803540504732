import React from 'react';
import loginImg from "../assets/img/Services/Rectangle 4622.png";
import overlayImg from "../assets/img/Login/Rectangle 4621.png";
import Footer from '../component/Footer';
import HeaderHome from '../component/HeaderHome';
import JobUpdates from '../component/JobUpdates';
import Signature from '../assets/Logo/Mylvaganam.png';

const ModernSlavery = () => {
  return (
    <>
      <HeaderHome />
    <div className="my-[60px] mx-[50px] shadow-lg rounded-xl flex flex-col justify-center">
        <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[20px] md:text-[30px] lg:text-[50px] z-20'>Modern Slavery ACT 2015</div>
            <img src={loginImg} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
        <div className="mt-0 mx-auto px-8 py-8">
        <table data-aos="zoom-in" className="mt-12 w-[100%] bg-white border-2">
          <thead className='border-2 border-black'>
            <td className='border-2 border-black'></td>
            <td>Farha MOHAMMAD</td>
          </thead>
          <tbody className='border-2'>
            <tr className='border-2'>
            <td className='border-2 font-bold border-black'>Model Policy Name:</td>
                <td className='border-2 border-black'>Modern Slavery ACT 2015 Policy</td>
            </tr>
            <tr className='border-2'>
            <td className='border-2 font-bold border-black'>Date:</td>
                <td className='border-2 border-black'>September 2023</td>
            </tr>
            <tr className='border-2'>
            <td className='border-2 font-bold border-black'>Version:</td>
                <td className='border-2 border-black'>1</td>
            </tr>
        </tbody>
          </table>
        <p data-aos="fade-up" className="mt-40 text-center font-bold text-[24px] sm:text-[30px] md:text-[40px]">MODERN SLAVERY ACT 2015</p>
        <p data-aos="zoom-in" className="mt-12 font-bold text-[24px] sm:text-[30px] mb-2">Here are the steps RHC 360 Solutions has taken and continues to take to understand and minimise the potential risk of modern slavery in its business and supply chains.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">This statement is published in line with section 54(1) of the Modern Slavery Act 2015.</p>

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">About RHC 360 Solutions</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">At RHC360 SOlutions, we believe that every individual deserves access to high-quality healthcare services that prioritize their well-being, independence, and overall quality of life. As a trusted provider of healthcare services, we are committed to offering a range of specialized care services that cater to the diverse needs of our clients.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Our team of skilled and compassionate healthcare professionals are dedicated to delivering exceptional care that is tailored to the unique needs of everyone. We take pride in offering bespoke care packages that are designed to promote independence and enhance the overall quality of life of our clients.</p>
        {/* <div data-aos="fade-up"> */}
        {/* <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Once the turnover threshold has been achieved, RHC 360 Solutions will produce a statement on our policy for Modern Slavery and will have a risk assessment, compliance, and will conduct due diligence of all workflows and processes.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Should there be a change in legislation, this will be reviewed accordingly.</p> */}
        {/* <div data-aos="fade-up"> */}
        <a href="" className='mt-5 text-3 mb-2' style={{textDecoration:"underline"}}>Find out more about RHC 360 Solutions</a>
        {/* </div> */}

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Our commitment to the principles of the Modern Slavery Act 2015</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">RHC 360 Solutions is committed to the principles of the Modern Slavery Act 2015 and the abolition of modern slavery and human trafficking.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">As an equal opportunities employer, we're committed to creating and ensuring a nondiscriminatory and respectful working environment for our staff. We want all our staff to feel confident that they can expose wrongdoing without any risk to themselves.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Our recruitment and people management processes are designed to ensure that all prospective employees are legally entitled to work in the UK and to safeguard employees from any abuse or coercion.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">We do not enter into business with any organisation, in the UK or abroad, which knowingly supports or is found to be involved in slavery, servitude and forced or compulsory labour.</p>

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Our supply chain</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Due to the nature of our business, we assess ourselves to have a low risk of modern slavery in our business and supply chains.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Our supply chains are limited and we procure goods and services from a restricted range of UK and overseas suppliers, mainly through UK government frameworks.</p>

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Our policies in relation to the Modern Slavery Act 2015</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">The following policies are available to all staff through the RHC 360 Solutions intranet:</p>
        <ul data-aos="zoom-in" className='mt-5 ml-5 list-disc text-3'>
            <li>Code of conduct </li>
            <li>Ethics policy</li>
            <li>Speak Up policy </li>
            <li>Bullying and harassment policy </li>
            <li>Diversity and inclusion policy </li>
            <li>Recruitment and selection policy</li>
        </ul>

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Embedding the principles</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">We will continue to embed the principles through:</p>
        <ul data-aos="zoom-in" className='mt-5 ml-5 list-disc text-3'>
            <li>Providing awareness training to staff on the Modern Slavery Act 2015 and informing them of the appropriate action to take if they suspect a case of slavery or human trafficking</li>
            <li>Ensuring staff involved in procurement activity are aware of and follow modern slavery procurement guidance on GOV.UK</li>
            <li>Ensuring that consideration of the modern slavery risks and prevention are added to RHC 360 Solutions's policy review process as an employer and procurer of goods and services </li>
            <li>Making sure RHC 360 Solutions and UK SBS procurement strategies and contract terms and conditions include references to modern slavery and human trafficking </li>
            <li>Continuing to take action to embed a zero tolerance policy towards modern slavery.</li>
            <li>Ensuring that staff involved in buying or procurement and the recruitment and deployment of workers receive training on modern slavery and ethical employment practices</li>
        </ul>

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">This statement has been approved by M. Mylvaganam, RHC 360 Solutions Chairman.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">This statement will be reviewed and updated every year.</p>
        <img src={Signature} alt="sign" className='mt-5'/>
        <p data-aos="zoom-in" className="font-bold mt-2 text-3">Mylvaganam Mahadevan ( Director )</p>
        <p data-aos="zoom-in" className="text-3">25/09/2023</p>
      </div>
      </div>
      
      <div className="mx-[40px] my-[50px]">
        <JobUpdates/>
        </div>
      <Footer />
    </>
  );
};

export default ModernSlavery;
